<!-- <div class="modal-header">
    <h4 class="modal-title">Consultation Invoice</h4>
    <span class="text-right mx-3">
        <a title="Download attachment" class="btn btn-orange btn-sm" (click)="printInvoice()">
            <i class="fa fa-download"></i>
        </a>
        <button type="button" class="close-light-btn btn-sm border-0 mx-1" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
            &times;
        </button>
    </span>
</div> -->
<div *ngIf="invoice">
    <div class="card">
        <div class="row head-bg p-4 m-0 mb-3">
            <div class="col-lg-8">
                <img src="../../../../assets/img/eazyopNewLogo.png" width="130" height="40">
            </div>
            <div class="col-lg-4">
                <p class="header m-0">Invoice for Consultation</p>
                <p class="sub-head m-0">Invoice ID: {{invoice.bill.invoiceNo}}</p>
            </div>
        </div>
        <div class="row p-4">
            <div class="col-lg-8">
                <p class="my-2 mx-0 patient-desc font-weight-bold">Patient</p>
                <p class="my-1 mx-0 patient-desc">Name: {{invoice.patient_name}}</p>
                <p class="my-1 mx-0 patient-desc">Phone: {{invoice.patient_mobile}} </p>
                <p class="my-1 mx-0 patient-desc">Date: {{invoice.bill.dateTime | date : 'dd MMM yyyy'}}</p>
            </div>
            <div class="col-lg-4">
                <p class="my-2 mx-0 patient-desc font-weight-bold">Doctor</p>
                <p class="my-1 mx-0 patient-desc">Name: {{invoice.doctorName}}</p>
                <p class="my-1 mx-0 patient-desc">Dentist - MRN:3952</p>
            </div>
        </div>
        <div class="row p-4">
            <div class="table-responsive">
                <table class="border-collapse w-100 py-3">
                    <thead>
                        <tr class="table-headings">
                            <th class="text-left p-2">Description</th>
                            <th class="text-right p-2"></th>
                            <th class="text-right p-2"></th>
                            <th class="text-center p-2">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left p-2">Doctor Consultation</td>
                            <td></td>
                            <td></td>
                            <td class="text-center p-2">${{invoice.bill.netAmount}}</td>
                        </tr>
                        <tr>
                            <td class="text-left p-2">Discount</td>
                            <td></td>
                            <td></td>
                            <td class="text-center p-2"> 0</td>
                        </tr>
                    </tbody>
                    <tfoot class="mb-3">
                        <tr class="border-top border-bottom font-weight-bold">
                            <td colspan="3" class="text-left px-2 py-3">Total value</td>
                            <td class="text-center px-2 py-3">${{invoice.bill.netAmount}}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    </div>
</div>