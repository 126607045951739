<header class="header">
  <nav class="navbar navbar-expand-lg header-nav no-border nav-padding">
    <div class="navbar-header">
      <a (click)="home()" class="navbar-brand logo">
        <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
      </a>
      <a id="mobile_btn">
        <span class="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-header">
        <a class="menu-logo">
          <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
        </a>
        <a id="menu_close" class="menu-close">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <!-- public -->
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']" *ngIf="!auth">
        <li [class.active]="
            base === 'doctor' ||
            (base === 'patients' && page === 'patient-profile')
          " (click)="addStyle('doctor')" class="has-submenu cursor-p header-text">
          <a (click)="doctor('doctor')">Doctors <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu header-text" id="doctor">
            <li [class.active]="base === 'doctor' && page === 'dashboard'">
              <a [routerLink]="'doctor/dashboard'">Dashboard</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'appointment'">
              <a [routerLink]="'doctor/appointment'">Appointments</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'patients'">
              <a [routerLink]="'doctor/patients'">Patients List</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'invoice'">
              <a [routerLink]="'doctor/invoice'">Invoices</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'manager'">
              <a [routerLink]="'doctor/manager'">Clinic Manager</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'support'">
              <a [routerLink]="'doctor/support'">Support</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'reviews'">
              <a [routerLink]="'doctor/reviews'">Reviews</a>
            </li>
          </ul>
        </li>
        <li [class.active]="base === 'map-grid' || base === 'map-list'" class="has-submenu cursor-p header-text"
          (click)="addStyle('patient')" routerLinkActive="active">
          <a>Patients <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu header-text" id="patient">
            <li [class.active]="base === 'map-grid' || base === 'map-list'" class="has-submenu cursor-p">
              <a>Doctors</a>
              <ul class="submenu">
                <li [class.active]="base === 'map-grid'">
                  <a (click)="mapGrid()">Map Grid</a>
                </li>
                <li [class.active]="base === 'map-list'">
                  <a (click)="mapList()">Map List</a>
                </li>
              </ul>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-doctor'">
              <a [routerLink]="'patients/search-doctor'">Search Doctor</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-hospital'">
              <a [routerLink]="'patients/search-hospital'">Search Pharmacy</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-clinic'">
              <a [routerLink]="'patients/search-clinic'">Search Clinic</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'doctor-profile'">
              <a [routerLink]="'patients/doctor-profile'">Doctor Profile</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'booking'">
              <a [routerLink]="'patients/booking'">Booking</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'checkout'">
              <a [routerLink]="'patients/checkout'">Checkout</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'success'">
              <a [routerLink]="'patients/success'">Booking Success</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'dashboard'">
              <a [routerLink]="'patients/dashboard'"> Patient Dashboard</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'favourites'">
              <a [routerLink]="'patients/favourites'">Favourites</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'settings'">
              <a [routerLink]="'patients/settings'">Profile Settings</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'support'">
              <a [routerLink]="'doctor/support'">Support</a>
            </li>
            <li [class.active]="
                base === 'patients' && page === 'patients-change-password'
              ">
              <a [routerLink]="'patients/patients-change-password'">Change Password</a>
            </li>
          </ul>
        </li>
        <li [class.active]="
            base === 'calender' ||
            base === 'blank' ||
            base === 'login-page' ||
            base === 'Register' ||
            base === 'forgot-password' ||
            (base === 'patients' && page === 'search-doctor') ||
            (base === 'patients' && page === 'component') ||
            (base === 'doctor' && page === 'invoice') ||
            base === 'invoice-details'
          " (click)="addStyle('pages')" class="has-submenu cursor-p header-text" pharmacy>
          <a>Pages <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu" id="pages">
            <li [class.active]="base === 'calender'">
              <a [routerLink]="'calender'">Calendar</a>
            </li>
            <li [class.active]="
                (base === 'doctor' && page === 'invoice') ||
                base === 'invoice-details'
              " (click)="addStyle('invoice')" class="has-submenu cursor-p">
              <a>Invoices </a>
              <ul class="submenu" id="invoice">
                <li [class.active]="base === 'doctor' && page === 'invoice'">
                  <a [routerLink]="'doctor/invoice'">Invoices</a>
                </li>
                <li [class.active]="base === 'invoice-details'">
                  <a [routerLink]="'invoice-details'">Invoice View</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <!-- patient -->
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']"
        *ngIf="auth && appType == appTypes.Patient">
        <li [class.active]="base === 'patients' && page === 'dashboard'">
          <a [routerLink]="'patients/dashboard'">Dashboard</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'appointment'">
          <a [routerLink]="'patients/appointment'">Appointments</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'order-history'">
          <a [routerLink]="'patients/order-history'">Orders</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'medical-records'">
          <a [routerLink]="'patients/medical-records'">Medical Records</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'prescription'">
          <a [routerLink]="'patients/prescription'">Prescriptions</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'medical-details'">
          <a [routerLink]="'patients/medical-details'">Medical Details</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'favourites'">
          <a [routerLink]="'/patients/favourites'">Favourites</a>
        </li>
      </ul>

      <!-- Doctor -->
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']"
        *ngIf="auth && appType == appTypes.Doctor">
        <li [class.active]="base === 'doctor' && page === 'dashboard'">
          <a [routerLink]="'doctor/dashboard'">Dashboard</a>
        </li>
        <li [class.active]="base === 'doctor' && page === 'appointment'">
          <a [routerLink]="'doctor/appointment'">Appointments</a>
        </li>
        <!-- <li [class.active]="base === 'doctor' && page === 'patients'">
          <a [routerLink]="'doctor/patients'">My Patients</a>
        </li> -->
        <li [class.active]="
            base === 'doctor' ||
            (base === 'patients' && page === 'patient-profile')
          " (click)="addStyle('Pharmacy')" class="has-submenu cursor-p header-text">
          <a (click)="doctor('Pharmacy')">Pharmacy <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu header-text" id="doctor">
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'pharmacy/dashboard'">Dashboard</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'pharmacy/accounts'">Accounts</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'pharmacy/products'">Product</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'pharmacy/orders'">Orders</a>
            </li>
            <li [class.active]="base === 'doctor' ">
              <a [routerLink]="'pharmacy/patient-search'">Patients</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'pharmacy/pharmacy-locations'">Pharmacy Details</a>
            </li>
          </ul>
        </li>
        <li [class.active]="
        base === 'doctor' ||
        (base === 'patients' && page === 'patient-profile')
      " (click)="addStyle('Doctor')" class="has-submenu cursor-p header-text">
          <a (click)="doctor('Doctor')">Configuration <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu header-text" id="doctor">
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'doctor/locations'">My Clinic Locations</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'doctor/unavailable-timings'">Unavailable Timings</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'doctor/invoice'">Invoice</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'doctor/reviews'">Reviews</a>
            </li>
            <li [class.active]="base === 'doctor'">
              <a [routerLink]="'doctor/manager'">Clinic Manager</a>
            </li>
          </ul>
        </li>
        <!-- global search -->
        <li class="mt-2" *ngIf="auth && appType == appTypes.Doctor">
          <app-global-search></app-global-search>
        </li>
      </ul>
      <!-- Pharmacy -->
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']"
        *ngIf="auth && appType == appTypes.Pharmacy">
        <li [class.active]="base === 'pharmacy' && page === 'dashboard'">
          <a [routerLink]="'pharmacy/dashboard'">Dashboard</a>
        </li>
        <li [class.active]="base === 'pharmacy' && page === 'orders'">
          <a [routerLink]="'pharmacy/orders'">Orders</a>
        </li>
        <li [class.active]="base === 'pharmacy' && page === 'patient-search'">
          <a [routerLink]="'pharmacy/patient-search'">Search</a>
        </li>
        <li [class.active]="base === 'pharmacy' && page === 'invoices'">
          <a [routerLink]="'pharmacy/invoices'">Invoices</a>
        </li>
        <li [class.active]="base === 'pharmacy' && page === 'accounts'">
          <a [routerLink]="'pharmacy/accounts'">Accounts</a>
        </li>
        <li [class.active]="
        base === 'pharmacy' ||
        (base === 'pharmacy' && page === 'configuration')
      " (click)="addStyle('Phamracy')" class="has-submenu cursor-p header-text">
          <a (click)="doctor('Pharmacy')">Configuration <i class="fas fa-chevron-down"></i></a>
          <ul class="submenu header-text" id="pharmacy">
            <li [class.active]="base === 'pharmacy'">
              <a [routerLink]="'pharmacy/products'">Products</a>
            </li>
            <li [class.active]="base === 'pharmacy'">
              <a [routerLink]="'pharmacy/pharmacy-locations'">Pharmacy Settings</a>
            </li>
            <li [class.active]="base === 'pharmacy'">
              <a [routerLink]="'pharmacy/manager'">Pharmacy Manager</a>
            </li>
          </ul>
        </li>
      </ul>

      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']">
        <li class="nav-item dropdown has-arrow logged-item border-0 d-lg-none" *ngIf="auth">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img *ngIf="!user?.photo_url" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
              <img *ngIf="user?.photo_url" [src]="user?.photo_url" alt="User Image" class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ user?.name }}</h6>
              <p class="text-muted mb-0 text-capitalize">{{ user?.role }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="location-update p-2 h5 mt-2 d-none d-lg-block" *ngIf="auth && appType == appTypes.Doctor">
      {{selectedClinic?.clinicName }}
    </div>
    
    <div class="location-update p-2 h5 mt-2 d-none d-lg-block" *ngIf="auth && appType == appTypes.Pharmacy">
      {{selectedPharmacy?.pharmacyLocationName }}
    </div>
    <ul class="nav header-navbar-rht margin-left-auto ">
      <li class="nav-item partners dropdown has-arrow logged-item" *ngIf="!auth">
        <a class="dropdown-toggle-bell nav-link cursor-p partner-text" data-toggle="dropdown">
          Partners Login
        </a>
        <div class="dropdown-menu dropdown-menu-right pos-menu">
          <a class="dropdown-item cursor-p" [routerLink]="'/doctor-login'">For Doctors</a>
          <a class="dropdown-item cursor-p" [routerLink]="'/pharmacy-login'">For Pharmacy</a>
        </div>
      </li>
      <li [class.active]="base === 'login-page'" class="nav-item" *ngIf="!auth">
        <a class="nav-link header-login cursor-p" [routerLink]="'login-page'">login / Signup
        </a>
      </li>
      <li class="nav-item px-0">
        <a class="nav-link cursor-p" [routerLink]="'/patients/support'" *ngIf="auth && appType == appTypes.Patient">
          <i class='fas fa-user-cog gear-icon'></i>
        </a>
        <a class="nav-link cursor-p" [routerLink]="'/doctor/support'" *ngIf="auth && appType == appTypes.Doctor">
          <i class='fas fa-user-cog gear-icon'></i>
        </a>
        <a class="nav-link cursor-p" [routerLink]="'/pharmacy/support'" *ngIf="auth && appType == appTypes.Pharmacy">
          <i class='fas fa-user-cog gear-icon'></i>
        </a>
      </li>
      <li [class.active]="base === 'login-page'" class="nav-item px-0" *ngIf="auth && appType == appTypes.Patient">
        <a class="nav-link cursor-p" [routerLink]="'/checkout'">
          <i class="fa fa-shopping-cart cart-styling">{{cartCount}}</i>
        </a>
      </li>
      <!-- Notification -->
      <li class="notify nav-item dropdown has-arrow logged-item pr-1" *ngIf="auth">
        <a class="dropdown-toggle-bell nav-link cursor-p " data-toggle="dropdown">
          <i class="fa fa-bell bell-styling"></i>
        </a>
        <div class="dropdown-menu mr-3 notification dropdown-menu-right pos-menu" *ngIf="notificationMessages.length">
          <div class="user-header py-3" *ngFor="let notification of notificationMessages">
            <div class="avatar avatar-sm">
              <img *ngIf="user?.photo_url == null || user?.photo_url == undefined"
                src="../../../assets/img/male-avatar.png" alt="User Image" class="avatar-img rounded-circle" />
              <img *ngIf="user?.photo_url != null && user?.photo_url != undefined" [src]="user?.photo_url"
                alt="User Image" class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ notification.title }}</h6>
              <p class="text-muted mb-0 text-capitalize font-12">{{ notification.body }}</p>
            </div>
          </div>
          <hr class="m-0">
        </div>
        <div class="dropdown-menu mr-3 notification dropdown-menu-right pos-menu" *ngIf="!notificationMessages.length">
          <p class="py-3 text-center text-info">No notifications available</p>
        </div>
      </li>
      <!-- /Notification -->
      <!-- User Menu -->
      <li class="nav-item dropdown has-arrow logged-item" *ngIf="auth">
        <a class="dropdown-toggle nav-link cursor-p" data-toggle="dropdown">
          <span class="user-img">
            <img *ngIf="!user?.photo_url" class="rounded-circle" src="../../../assets/img/male-avatar.png" width="31" />
            <img *ngIf="user?.photo_url" class="rounded-circle" [src]="user?.photo_url" width="31" />
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right pos-menu">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img *ngIf="!user?.photo_url" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
              <img *ngIf="user?.photo_url" [src]="user?.photo_url" alt="User Image" class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ user?.name }}</h6>
              <p class="text-muted mb-0 text-capitalize">{{ user?.role }}</p>
            </div>
          </div>
          <!-- doctor -->
          <span *ngIf="appType == appTypes.Doctor">
            <a class="dropdown-item cursor-p" [routerLink]="'/doctor/settings'">Profile</a>
            <a class="dropdown-item cursor-p" [routerLink]="'doctor/patients'">My Patients</a>
            <a class="dropdown-item cursor-p" (click)="openClinicSelectionModal()">Clinics</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/doctor/accounts'">Accounts</a>
            <a class="dropdown-item cursor-p" (click)="openChangepassDoctorModal()">Change
              Password</a>
          </span>
          <!-- patient -->
          <span *ngIf="appType == appTypes.Patient">
            <a class="dropdown-item cursor-p" [routerLink]="'/patients/profile'">Profile</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/patients/address'">Address</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/patients/dependent'">Dependent</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/patients/accounts'">Transactons</a>
            <a class="dropdown-item cursor-p" (click)="openChangepassPatientModal()">Change Password</a>
          </span>

          <!-- pharmacy -->
          <span *ngIf="appType == appTypes.Pharmacy">
            <a class="dropdown-item cursor-p" [routerLink]="'/pharmacy/settings'">Profile</a>
            <a class="dropdown-item cursor-p" (click)="openPharmacySelectionModal()">Pharmacy Stores</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/pharmacy/invoice-details'">Invoice Details</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/pharmacy/order-details'">Order Details</a>
            <a class="dropdown-item cursor-p" [routerLink]="'/pharmacy/recent-orders'">Recent Orders</a>
            <a class="dropdown-item cursor-p" (click)="openChangepassPatientModal()">Change Password</a>
          </span>

          <a class="dropdown-item cursor-p" (click)="logout()">Logout</a>
        </div>
      </li>
      <!-- /User Menu -->
    </ul>
  </nav>
</header>


<!-- New Header -->
<!-- <header class="header">
  <nav class="navbar navbar-expand-lg header-nav no-border nav-padding">
    <div class="navbar-header">
      <a (click)="home()" class="navbar-brand logo">
        <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
      </a>
      <a id="mobile_btn">
        <span class="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-header">
        <a class="menu-logo">
          <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
        </a>
        <a id="menu_close" class="menu-close">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']">
        <li [class.active]="
            base === 'doctor' ||
            (base === 'patients' && page === 'patient-profile')
          " (click)="addStyle('doctor')" class="has-submenu cursor-p header-text">
          <a (click)="doctor('doctor')">Doctors </a>
          <ul class="submenu header-text" id="doctor">
            <li [class.active]="base === 'doctor' && page === 'dashboard'">
              <a [routerLink]="'doctor/dashboard'">Dashboard</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'appointment'">
              <a [routerLink]="'doctor/appointment'">Appointments</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'patients'">
              <a [routerLink]="'doctor/patients'">Patients List</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'patient-profile'">
              <a [routerLink]="'patients/patient-profile'">Patients Profile</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'invoice'">
              <a [routerLink]="'doctor/invoice'">Invoices</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'manager'">
              <a [routerLink]="'doctor/manager'">Clinic Manager</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'support'">
              <a [routerLink]="'doctor/support'">Support</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'reviews'">
              <a [routerLink]="'doctor/revie ws'">Reviews</a>
            </li>
          </ul>
        </li>
        <li [class.active]="base === 'map-grid' || base === 'map-list'" class="has-submenu cursor-p header-text"
          (click)="addStyle('patient')" routerLinkActive="active">
          <a>Labs</a>
          <ul class="submenu header-text" id="patient">
            <li [class.active]="base === 'map-grid' || base === 'map-list'" class="has-submenu cursor-p">
              <a>Doctors</a>
              <ul class="submenu">
                <li [class.active]="base === 'map-grid'">
                  <a (click)="mapGrid()">Map Grid</a>
                </li>
                <li [class.active]="base === 'map-list'">
                  <a (click)="mapList()">Map List</a>
                </li>
              </ul>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-doctor'">
              <a [routerLink]="'patients/search-doctor'">Search Doctor</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-hospital'">
              <a [routerLink]="'patients/search-hospital'">Search Hospital</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'search-clinic'">
              <a [routerLink]="'patients/search-clinic'">Search Clinic</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'doctor-profile'">
              <a [routerLink]="'patients/doctor-profile'">Doctor Profile</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'booking'">
              <a [routerLink]="'patients/booking'">Booking</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'checkout'">
              <a [routerLink]="'patients/checkout'">Checkout</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'success'">
              <a [routerLink]="'patients/success'">Booking Success</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'dashboard'">
              <a [routerLink]="'patients/dashboard'"> Patient Dashboard</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'favourites'">
              <a [routerLink]="'patients/favourites'">Favourites</a>
            </li>
            <li [class.active]="base === 'patients' && page === 'settings'">
              <a [routerLink]="'patients/settings'">Profile Settings</a>
            </li>
            <li [class.active]="base === 'doctor' && page === 'support'">
              <a [routerLink]="'doctor/support'">Support</a>
            </li>
            <li [class.active]="
                base === 'patients' && page === 'patients-change-password'
              ">
              <a [routerLink]="'patients/patients-change-password'">Change Password</a>
            </li>
          </ul>
        </li>

        <li [class.active]="
            base === 'calender' ||
            base === 'blank' ||
            base === 'login-page' ||
            base === 'Register' ||
            base === 'forgot-password' ||
            (base === 'patients' && page === 'search-doctor') ||
            (base === 'patients' && page === 'component') ||
            (base === 'doctor' && page === 'invoice') ||
            base === 'invoice-details'
          " (click)="addStyle('pages')" class="has-submenu cursor-p header-text" pharmacy>
          <a>Clinic</a>
          <ul class="submenu" id="pages">
            <li [class.active]="base === 'calender'">
              <a [routerLink]="'calender'">Calendar</a>
            </li>
            <li [class.active]="
                (base === 'doctor' && page === 'invoice') ||
                base === 'invoice-details'
              " (click)="addStyle('invoice')" class="has-submenu cursor-p">
              <a>Invoices </a>
              <ul class="submenu" id="invoice">
                <li [class.active]="base === 'doctor' && page === 'invoice'">
                  <a [routerLink]="'doctor/invoice'">Invoices</a>
                </li>
                <li [class.active]="base === 'invoice-details'">
                  <a [routerLink]="'invoice-details'">Invoice View</a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="has-submenu cursor-p header-text"><a>Blog</a></li>
      </ul>
    </div>
    <div class="location-update p-3">
      {{ selectedClinic?.clinicName }}
    </div>
    <ul class="nav header-navbar-rht" *ngIf="comrytmenu">
      <li class="nav-item">
        <a class="nav-link header-text" [routerLink]="'/doctor-login'" *ngIf="!auth">For Doctors</a>
      </li>
      <li [class.active]="base === 'login-page'" class="nav-item" *ngIf="!auth">
        <a class="nav-link header-login" [routerLink]="'login-page'">login / Signup
        </a>
      </li>
      <li class="nav-item dropdown has-arrow logged-item" *ngIf="auth">
        <a class="dropdown-toggle nav-link" data-toggle="dropdown">
          <span class="user-img">
            <img *ngIf="!isPatient" class="rounded-circle" src="../../../assets/img/male-avatar.png" width="31"
              alt="Darren Elder" />
            <img *ngIf="isPatient" class="rounded-circle" src="../../../assets/img/male-avatar.png" width="31"
              alt="Richard Wilson" />
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right pos-menu">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img *ngIf="!isPatient" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
              <img *ngIf="isPatient" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ user?.name }}</h6>
              <p class="text-muted mb-0 text-capitalize">{{ user?.role }}</p>
            </div>
          </div>
          <a class="dropdown-item cursor-p" *ngIf="!isPatient" [routerLink]="'/doctor/dashboard'">Dashboard</a>
          <a class="dropdown-item cursor-p" *ngIf="!isPatient" [routerLink]="'/doctor/settings'">Profile Settings</a>
          <a class="dropdown-item cursor-p" *ngIf="!isPatient" (click)="openClinicSelectionModal()">Clinics</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/dashboard'">Dashboard</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/settings'">Profile Settings</a>
          <a class="dropdown-item cursor-p" *ngIf="!isPatient" (click)="openChangepassDoctorModal()">Change Password</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" (click)="openChangepassPatientModal()">Change Password</a>
          <a class="dropdown-item cursor-p" (click)="logout()">Logout</a>
        </div>
      </li>

    </ul>
  </nav>
</header> -->


<!--  PATIENT HEADER -->

<!-- <header class="header">
  <nav class="navbar navbar-expand-lg header-nav no-border nav-padding">
    <div class="navbar-header">
      <a (click)="home()" class="navbar-brand logo">
        <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
      </a>
      <a id="mobile_btn">
        <span class="bar-icon">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </a>
    </div>
    <div class="main-menu-wrapper">
      <div class="menu-header">
        <a class="menu-logo">
          <img src="assets/img/eazyopNewLogo.png" class="img-fluid cursor-p" alt="Logo" />
        </a>
        <a id="menu_close" class="menu-close">
          <i class="fas fa-times"></i>
        </a>
      </div>
      <ul class="main-nav" [ngClass]="[base === 'home-seven' ? 'white-font' : '']">
        <li [class.active]="base === 'patients' && page === 'dashboard'">
          <a [routerLink]="'patients/dashboard'">Dashboard</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'appointment'">
          <a [routerLink]="'patients/appointment'">Appointment</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'order-history'">
          <a [routerLink]="'patients/order-history'">My Orders</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'medical-records'">
          <a [routerLink]="'patients/medical-records'">Medical Records</a>
        </li>
        <li [class.active]="base === 'patients' && page === 'medical-details'">
          <a [routerLink]="'patients/medical-details'">Medical Details</a>
        </li>
        <li class="nav-item dropdown has-arrow logged-item border-0 d-lg-none" *ngIf="auth">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img *ngIf="!user?.photo_url" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
              <img *ngIf="user?.photo_url" [src]="user?.photo_url" alt="User Image" class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ user?.name }}</h6>
              <p class="text-muted mb-0 text-capitalize">{{ user?.role }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <ul class="nav header-navbar-rht" *ngIf="comrytmenu">
      <li class="nav-item dropdown has-arrow logged-item" *ngIf="auth">
        <a class="dropdown-toggle nav-link cursor-p" data-toggle="dropdown">
          <span class="user-img">
            <img *ngIf="!user?.photo_url" class="rounded-circle" src="../../../assets/img/male-avatar.png" width="31" />
            <img *ngIf="user?.photo_url" class="rounded-circle" [src]="user?.photo_url" width="31" />
          </span>
        </a>
        <div class="dropdown-menu dropdown-menu-right pos-menu">
          <div class="user-header">
            <div class="avatar avatar-sm">
              <img *ngIf="!user?.photo_url" src="../../../assets/img/male-avatar.png" alt="User Image"
                class="avatar-img rounded-circle" />
              <img *ngIf="user?.photo_url" [src]="user?.photo_url" alt="User Image" class="avatar-img rounded-circle" />
            </div>
            <div class="user-text">
              <h6>{{ user?.name }}</h6>
              <p class="text-muted mb-0 text-capitalize">{{ user?.role }}</p>
            </div>
          </div>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/favourites'">Favourites</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/dependent'">Dependent</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/accounts'">Transactons</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/settings'">Profile</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" [routerLink]="'/patients/support'">Support</a>
          <a class="dropdown-item cursor-p" *ngIf="isPatient" (click)="openChangepassPatientModal()">Change Password</a>
          <a class="dropdown-item cursor-p" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
  </nav>
</header> -->