<div class="content">
    <div class="container my-2">
        <div class="row">
            <div class="col-12 text-center margin-body">
                <!-- <img src=" ../../../../assets/img/successfully.svg"> -->
                <img src="../../../../assets/img/verified.gif" height="80" width="100">
                <p class="success-text my-3">Registration Completed<br>
                    Successfully</p>
                <p class="verification-text">we will verify your account soon </p>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 text-center footer-logo">
                <img src="../../../../assets/img/eazyopNewLogo.png" width="194" height="41">
            </div>
        </div> -->
    </div>
</div>