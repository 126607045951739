import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from './../models/product.model';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  private dataSubject = new BehaviorSubject<any>(null);
  public dataAddtoCart$ = this.dataSubject.asObservable();

  sendDataAddtoCart(data: any): void {
    this.dataSubject.next(data);
  }

  addToCartMedicineByPatient(data){
    return this.http.post(this.baseUrl + '/v1/pharmacy/addto-cart', data);
  }

  getCartItemsByPatient(patientId) {
    return this.http.get(this.baseUrl + '/v1/pharmacy/' + patientId + '/checkout');
  }

  deleteToCartMedicineByPatient(patientId, productId){
    return this.http.delete(this.baseUrl + '/v1/pharmacy/deleteOneByPatientIdAndMedicineId/' + patientId + '/' + productId);
  }


}
