import { Injectable } from '@angular/core';
import { AppType } from '../models/app-type';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private authenticationService: AuthenticationService) { }

  isUserLoggedIn() {
    let token = localStorage.getItem('token');
    let user = this.authenticationService.userValue;
    if (user && token) {
      return true;
    }
    return false;
  }

  isLoggedInUserPatient() {
    let token = localStorage.getItem('token');
    let user = this.authenticationService.userValue;
    if (user && token) {
      return user?.role?.toLowerCase() == 'patient';
    }
    return false;
  }

  isLoggedInUserDoctor() {
    let token = localStorage.getItem('token');
    let user = this.authenticationService.userValue;
    if (user && token) {
      let allowedRoles = ['doctor', 'receptionist', 'lab incharge', 'assistant'];
      return allowedRoles.includes(user?.role?.toLowerCase());
    }
    return false;
  }


  isLoggedInUserPharmacy() {
    let token = localStorage.getItem('token');
    let user = this.authenticationService.userValue;
    if (user && token) {
      let allowedRoles = ['pharmacy'];
      return allowedRoles.includes(user?.role?.toLowerCase());
    }
    return false;
  }

  getImpersonateId() {
    let token = localStorage.getItem('token');
    let user = this.authenticationService.userValue;
    if (user && token) {
      return user?.impersonateId;
    }
  }

  getUser() {
    return this.authenticationService.userValue;
  }

  getUserObservable() {
    return this.authenticationService.user;
  }

  getAppType() {
    if (this.isLoggedInUserPatient()) {
      return AppType.Patient;
    }
    else if (this.isLoggedInUserDoctor()) {
      return AppType.Doctor;
    }
    else if (this.isLoggedInUserPharmacy()) {
      return AppType.Pharmacy;
    }
  }

}
