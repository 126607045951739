import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ClinicService } from './clinic.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;

  public baseUrl = environment.apiUrl;

  constructor(private http: HttpClient,
    private clinicService: ClinicService,
    private router: Router) {

    let token = localStorage.getItem('token');
    if (token != null) {
      const user = JSON.parse(atob(token.split('.')[1]));
      this.userSubject = new BehaviorSubject<any>(user);
    }
    else {
      this.userSubject = new BehaviorSubject<any>(null);
    }
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): any {
    return this.userSubject.value;
  }

  registerDoctor(request) {
    let url = this.baseUrl + '/v1/auth/doctor/register';
    return this.http.post(url, request);
  }

  resendDoctorOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/auth/doctor/requestOTP', body);
  }

  resendPatientOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/auth/patient/requestOTP', body);
  }

  resendDoctorRegisterOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/auth/doctor/requestOTP', body);
  }

  resendPatientRegisterOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/auth/patient/requestOTP', body);
  }

  changeDoctorPassword(request) {
    let url = this.baseUrl + '/v1/auth/doctor/change-password';
    return this.http.post(url, request);
  }

  changePatientPassword(request) {
    let url = this.baseUrl + '/v1/auth/patient/change-password';
    return this.http.post(url, request);
  }

  forgotDoctorPassword(request) {
    let url = this.baseUrl + '/v1/auth/doctor/forgot-password';
    return this.http.post(url, request);
  }

  forgotPharmacyPassword(request) {
    let url = this.baseUrl + '/v1/pharmacy/forgot-password';
    return this.http.post(url, request);
  }

  forgotPatientPassword(request) {
    let url = this.baseUrl + '/v1/auth/patient/forgot-password';
    return this.http.post(url, request);
  }

  loginDoctor(request) {
    let url = this.baseUrl + '/v1/auth/doctor/login';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }

  requestDoctorOtp(value) {
    let request = { phone: value };
    let url = this.baseUrl + '/v1/auth/doctor/login';
    return this.http.post(url, request);
  }

  verifyLoginDoctorOtp(request) {
    let url = this.baseUrl + '/v1/auth/doctor/verify/login/otp';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }

  verifyRegisterDoctorOtp(request) {
    let url = this.baseUrl + '/v1/auth/doctor/verify/register/otp';
    return this.http.post(url, request);
  }

  registerHospital(request) {
    let url = this.baseUrl + '/api/v1/register-hospital';
    return this.http.post(url, request);
  }

  loginHospital(request) {
    let url = this.baseUrl + 'api/v1/login-hospital';
    return this.http.post(url, request);
  }

  logout() {
    debugger;
    localStorage.clear();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.clinicService.setSelectedClinic(null);
  }

  registerPatient(request) {
    let url = this.baseUrl + '/v1/auth/patient/register';
    return this.http.post(url, request);
  }

  loginPatient(request) {
    let url = this.baseUrl + '/v1/auth/patient/login';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }

  requestPatientOtp(value) {
    let request = { phone: value };
    let url = this.baseUrl + '/v1/auth/patient/login';
    return this.http.post(url, request);
  }

  verifyPatientOtp(request) {
    let url = this.baseUrl + '/v1/auth/patient/verify/login/otp';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }

  refreshToken() {
    let url = this.baseUrl + '/v1/refreshToken';

    let token = localStorage.getItem('refreshToken');

    return this.http.post(url, {}, {
      headers: { 'Authorization': 'Bearer ' + token }
    }).pipe(map((response: any) => {

      localStorage.setItem('refreshToken', response.data.refreshToken);
      localStorage.setItem('token', response.data.token);

      const user = JSON.parse(atob(response.data.token.split('.')[1]));

      this.userSubject.next(user);
      this.startRefreshTokenTimer();
      return user;
    })).subscribe(response => {

    }, err => {
      this.logout();
      this.router.navigate(['/']);
    });
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {

    let token = localStorage.getItem('token');
    if (token != undefined) {
      const jwtToken = JSON.parse(atob(token.split('.')[1]));

      // set a timeout to refresh the token a minute before it expires
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (2 * 60 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  registerPatientByDoctor(body: any) {
    let url = this.baseUrl + '/v1/auth/patient/registerBydoctor';
    return this.http.post(url, body);
  }

  registerPharmacy(request: any) {
    let url = this.baseUrl + '/v1/pharmacy/register';
    console.log("final::",url)
    return this.http.post(url, request);
  }

  verifyRegisterPharmacyOtp(request) {
    let url = this.baseUrl + '/v1/pharmacy/verify/register/otp';
    return this.http.post(url, request);
  }

  resendPharmacyRegisterOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/pharmacy/requestOTP', body);
  }

  requestPharmacyOtp(value : any) {
    let request = { phone: value };
    let url = this.baseUrl + '/v1/pharmacy/login';
    return this.http.post(url, request);
  }

  verifyLoginPharmacyOtp(request) {
    let url = this.baseUrl + '/v1/pharmacy/verify/login/otp';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }
  resendOtp(body: any) {
    return this.http.post(this.baseUrl + '/v1/auth/doctor/requestOTP', body);
  }

  loginPharmacy(request) {
    let url = this.baseUrl + '/v1/pharmacy/login';
    return this.http.post(url, request)
      .pipe(map((response: any) => {

        localStorage.setItem('refreshToken', response.data.refreshToken);
        localStorage.setItem('token', response.data.token);

        const user = JSON.parse(atob(response.data.token.split('.')[1]));
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        return response;
      }));
  }
}
