<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Change Password</h4>
    <button type="button" class="btn-close border-0 bg-white" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      &times;
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>New Password<span class="text-danger">*</span></label>
            <div class="inner-addon right-addon">
              <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" class="form-control" formControlName="password"
                  [ngClass]="{ 'is-invalid': f.password.touched && f.password.errors }">
                <div>
                  <i alt="show" class="far fa-eye eye-show" (click)="showPassword = !showPassword"
                    [class.hide]="showPassword"></i>
                  <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                    [class.hide]="!showPassword"></i>
                </div>
              </div>
            </div>
            <div *ngIf="f.password.touched && f.password.errors" class="invalid-feedback">
              <div>New password is required</div>
            </div>
          </div>
          <div class="form-group">
            <label>Confirm Password<span class="text-danger">*</span></label>
            <div class="inner-addon right-addon">
              <div class="input-group">
                <input [type]="showPasswordConfirm ? 'text' : 'password'" formControlName="confirmPassword"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': f.confirmPassword.touched && f.confirmPassword.errors }" />
                <div>
                  <i alt="show" class="far fa-eye eye-show" (click)="showPasswordConfirm = !showPasswordConfirm"
                    [class.hide]="showPasswordConfirm"></i>
                  <i alt="hide" class="far fa-eye-slash eye-hide" (click)="showPasswordConfirm = !showPasswordConfirm"
                    [class.hide]="!showPasswordConfirm"></i>
                </div>
              </div>
            </div>
            <div *ngIf="f.confirmPassword.touched && f.confirmPassword.errors" class="invalid-feedback">
              <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
              <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
          </div>
          <div class="submit-section text-right">
            <button type="submit" class="btn btn-action submit-btn" [disabled]="changePasswordForm.invalid">Save
              Changes</button>
          </div>
        </form>
        <!-- /Change Password Form -->
      </div>
    </div>
  </div>
</div>
<!-- /Page Content -->