<form [formGroup]="prescriptionForm" (ngSubmit)="onSubmit()">
  <div class="modal-header">
    <h4 class="modal-title">Add Prescription
    </h4>
    <button type="button" class="btn-close border-0 bg-white" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      &times;
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid px-0">
      <div class="row m-0">
        <!-- <div class="col-md-5 col-lg-4 col-xl-3">
          <div>
            <div class="card widget-profile pat-widget-profile" *ngIf="patientDetails?._id">
              <div class="card-body">
                <div class="pro-widget-content">
                  <div class="profile-info-widget">
                    <a class="booking-doc-img" *ngIf="!patientDetails?.photo">
                      <img src="../../../assets/img/male-avatar.png" alt="User Image">
                    </a>
                    <a class="booking-doc-img" *ngIf="patientDetails?.photo">
                      <img [src]="patientDetails?.photo" alt="User Image">
                    </a>
                    <div class="profile-det-info">
                      <h3>
                        <a [routerLink]="'../../patient-profile/' + patientDetails?._id">
                          {{ patientDetails.firstName + ' ' + patientDetails.lastName }}
                        </a>
                      </h3>
                      <div class="patient-details">
                        <h5 class="mb-0" *ngIf="patientDetails.city">
                          <i class="fas fa-map-marker-alt"></i>
                          {{ patientDetails.city }}, {{ patientDetails.state }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="patient-info">
                  <ul>
                    <li>Age <span>{{ patientDetails.age }} Years, {{ patientDetails.gender }}</span></li>
                    <li>Blood Group <span>{{ patientDetails.bloodGroup }}</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-12 col-lg-12 col-xl-12">
          <div class="card mb-2">
            <div class="card-body py-2 px-3">
              <div class="row" *ngIf="patientDetails?._id">
                <div class="col-md-8">
                  <div class="profile-info-widget">
                    <div class="profile-det-info">
                      <a class="h3 border-right">
                        {{ patientDetails.firstName + ' ' + patientDetails.lastName }}
                      </a>
                      <span class="pl-2"> {{ patientDetails.age }} Years, {{ patientDetails.gender
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body py-2 px-3">
              <div class="form-group">
                <label>Summary</label>
                <textarea class="form-control" formControlName="notes">
                </textarea>
              </div>
              <!-- Prescription Item -->
              <div class="card card-table table-card">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-hover table-center">
                      <thead>
                        <tr class="table-headings">
                          <th class="w-200">Medicine Name</th>
                          <th class="w-80">Quantity</th>
                          <th class="w-80">Days</th>
                          <th class="w-100">Time</th>
                          <th class="w-100-min">Consumption Time</th>
                        </tr>
                      </thead>
                      <tbody formArrayName="prescription"
                        *ngFor="let item of prescriptionForm.get('prescription')['controls']; let i = index;">
                        <span [formGroupName]="i" class="display-contents">
                          <tr class="bg-white border-0">
                            <td class="pb-0">
                              <div class="form-group form-height">
                                <input type="text" class="form-control width-400" (selectItem)="selectedItem($event, i)"
                                  [ngClass]="{ 'is-invalid': item.get('name').touched && item.get('name').errors }"
                                  [ngbTypeahead]="search" [inputFormatter]="formatter" [resultFormatter]="formatter" />
                                <div *ngIf="item.get('name').touched && item.get('name').errors?.required"
                                  class="invalid-feedback">
                                  <div>medicine name is required</div>
                                </div>
                              </div>
                            </td>
                            <td class="pb-0">
                              <div class="form-group form-height form-width">
                                <input type="number" min="0" class="form-control" formControlName="quantity"
                                  [ngClass]="{ 'is-invalid': item.get('quantity').touched && item.get('quantity').errors }">
                                <div *ngIf="item.get('quantity').touched && item.get('quantity').errors"
                                  class="invalid-feedback">
                                  <div>required</div>
                                </div>
                              </div>
                            </td>
                            <td class="pb-0">
                              <div class="form-group form-height form-width">
                                <input type="number" min="0" class="form-control" formControlName="days"
                                  [ngClass]="{ 'is-invalid': item.get('days').touched && item.get('days').errors }">
                                <div *ngIf="item.get('days').touched && item.get('days').errors"
                                  class="invalid-feedback">
                                  <div>required</div>
                                </div>
                              </div>
                            </td>
                            <td class="pb-0">
                              <div class="form-group form-height">
                                <select class="form-control width-120" formControlName="time1"
                                  [ngClass]="{ 'is-invalid': item.get('time1').touched && item.get('time1').errors }">
                                  <option *ngFor="let item of intakeTimeList" [value]="item.value">
                                    {{ item.name }}
                                  </option>
                                </select>
                                <div *ngIf="item.get('time1').touched && item.get('time1').errors"
                                  class="invalid-feedback">
                                  <div>required</div>
                                </div>
                              </div>
                            </td>
                            <td class="pb-0">
                              <div class="form-group form-height">
                                <select class="form-control width-180" formControlName="time2"
                                  [ngClass]="{ 'is-invalid': item.get('time2').touched && item.get('time2').errors }">
                                  <option value="undefined">-</option>
                                  <option value="AfterFood">After Food</option>
                                  <option value="BeforeFood">Before Food</option>
                                </select>
                                <div *ngIf="item.get('time2').touched && item.get('time2').errors"
                                  class="invalid-feedback">
                                  <div>required</div>
                                </div>
                              </div>
                            </td>
                            <td class="pb-0">
                              <button class="btn btn-md bg-danger-light margin-button cursor-p"
                                (click)="deletePrescription(i)"><i class="fas fa-trash"></i>
                              </button>
                            </td>
                          </tr>
                          <!-- <tr class="bg-white">
                            <td class="border-0 pt-0 pb-0" colspan="4">
                              <div class="form-group form-height">
                                <input type="text" class="form-control" placeholder="Notes" formControlName="notes">
                              </div>
                            </td>
                          </tr> -->
                        </span>
                      </tbody>
                    </table>
                    <div class="row m-0">
                      <div class="col-12">
                        <div class="add-more-item text-right cursor-p">
                          <a class="add-education" (click)="addPrescription()"><i class="fa fa-plus-circle"></i>
                            Add More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Prescription Item -->
              <!-- Signature -->
              <div class="row">
                <div class="col-md-12 text-right">
                  <div class="signature-wrap">
                    <!-- <div class="signature">
                      Click here to sign
                    </div> -->
                    <div class="sign-name">
                      <p class="mb-0">(Dr. {{ doctorDetails?.firstName + ' ' + doctorDetails?.lastName }})</p>
                      <span class="text-muted">Signature</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Signature -->
              <!-- Submit Section -->
              <div class="row">
                <div class="col-md-12">
                  <div class="submit-section">
                    <button type="submit" class="btn btn-orange submit-btn">Save</button>
                    <button type="reset" class="btn btn-secondary submit-btn">Clear</button>
                  </div>
                </div>
              </div>
              <!-- /Submit Section -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>