import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  submitted = false;
  contactUsForm: FormGroup;
  constructor(private formBuilder: FormBuilder) { }
  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }
  }
  onReset() {
    this.submitted = false;
    this.contactUsForm.reset();
  }

  ngOnInit(): void {

    this.contactUsForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[_A-z0-9]*((-|\s)*[_A-z0-9])*$')]],
      explain: ['', [Validators.required]],
      lastName: ['', Validators.required],
      concern: ['', Validators.required],
      issues: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    });
  }
}