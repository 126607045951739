import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from 'src/app/services/clinic.service';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-clinic-selection-modal',
  templateUrl: './clinic-selection-modal.component.html',
  styleUrls: ['./clinic-selection-modal.component.scss']
})
export class ClinicSelectionModalComponent implements OnInit {

  selectedClinic: any;
  clinics = [];
  pharmacyList: any = [];
  selectedPharmacy: any;

  constructor(public activeModal: NgbActiveModal,
    private clinicService: ClinicService, private PharmacyService: PharmacyService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.getClinics();
  }

  getClinics() {
    this.clinicService.getLocations()
      .subscribe((response: any) => {
        this.clinics = response.data;
        if (this.clinics.length > 0) {
          this.selectedClinic = this.clinics[0];
          localStorage.setItem('clinicId', this.selectedClinic._id);
          
        }
      });
  }

  selectClinic(clinic) {
    this.selectedClinic = clinic;
    this.clinicService.setSelectedClinic(this.selectedClinic);
    localStorage.setItem('clinicId', this.selectedClinic.clinicId);
    this.getPharmacy(this.selectedClinic.clinicId);
    this.activeModal.dismiss();
  }

  getPharmacy(selectedClinic) {
    let impersonateId = this.userService.getImpersonateId();
    let isAppType = this.userService.getAppType();
    this.PharmacyService.getPharmacy(impersonateId, isAppType, selectedClinic)
      .subscribe((response: any) => {
        if(response.code == 200 && response.data && response.data.length> 0) {
          this.selectedPharmacy = response.data[0];
          localStorage.setItem('pharmacyId', this.selectedPharmacy._id);
          localStorage.setItem('selectedPharmacy', JSON.stringify(this.selectedPharmacy));
        }
        
      }, err => {
        
      });
  }

}
