<div class="container-fluid bg-light-blue py-5">
    <div class="row m-0">
        <div class="col-md-7">
            <p class="title">Latest Interviews Videos</p>
        </div>
        <div class="col-md-4">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text bg-white border-0" id="basic-addon1"><img
                            src="../../assets/img/search.svg"></span>
                </div>
                <input type="text" class="form-control border-0 border-input" placeholder="Search Diseases Etc ">
            </div>
        </div>
        <div class="col-md-1 display-set text-right">
            <span class="filters-btn px-3 py-2">Filters</span>
        </div>
    </div>
</div>
<div class="container-fluid py-5 video-container">
    <div class="row justify-content-center m-0">
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center m-0">
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
        <div class="col-md-4 cursor-p" [routerLink]="'/video'">
            <div class="card card-border">
                <div class="card-header p-0">
                    <img src="../../assets/img/video-thumb.svg" class="w-100">
                </div>
                <div class="card-body px-md-2 px-lg-3 border-0 card-border">
                    <p class="video-title m-0 py-1">Dense Diabetic Tractional Retinal Detachment</p>
                    <p class="m-0 video-subtitle">Stephen Gee, MD</p>
                </div>
            </div>
        </div>
    </div>
</div>