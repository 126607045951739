import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPatientsByMobileNumber(mobileNumber: any) {
    return this.http.get(this.baseUrl + '/v1/patient/search?mobileNumber=' + mobileNumber);
  }

  getFavouriteDoctors() {
    return this.http.get(this.baseUrl + '/v1/patient/favouriteDoctorMapping/');
  }
  getFavouriteClinics() {
    return this.http.get(this.baseUrl + '/v1/patient/favouritePatientClinicMapping');
  }


  getPatientDetails() {
    return this.http.get(this.baseUrl + '/v1/patient');
  }

  deleteFavouriteDoctor(id: any) {
    return this.http.delete(this.baseUrl + '/v1/patient/favouriteDoctorMapping/' + id);
  }

  postMedicalRecord(data) {
    return this.http.post(this.baseUrl + '/v1/patient/medical-record', data);
  }

  getMedicalRecords(id, pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    return this.http.get(this.baseUrl + '/v1/patient/medical-record/' + id + query);
  }

  getMedicalRecordsByPatientId(id, pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    return this.http.get(this.baseUrl + '/v1/patient/medical-record/byPatientId/' + id + query);
  }

  deleteMedicalRecord(id) {
    return this.http.delete(this.baseUrl + '/v1/patient/medical-record/' + id);
  }

  downloadMedicalAttachment(id) {
    return this.http.get(this.baseUrl + '/v1/patient/medical-record/download/' + id);
  }

  getAllPatientsAndDependents() {
    return this.http.get(this.baseUrl + '/v1/patient/getByPatientAuthKey');
  }

  getPatientProfile() {
    return this.http.get(this.baseUrl + '/v1/patient');
  }

  getPatientProfileById(id: any) {
    return this.http.get(this.baseUrl + '/v1/patient/' + id);
  }

  updatePatientProfile(body: any) {
    let url = this.baseUrl + '/v1/patient/profile-update';
    return this.http.put(url, body);
  }

  getAppointments(startDate, toDate, apptype) {
    let params = { dateFrom: startDate, dateTo: toDate }
    if (apptype) {
      params["type"] = apptype
    }
    return this.http.get(this.baseUrl + '/v1/patient/getAppointmentByAuthKey', { params });
  }

  // getAppointmentsByPatientId(id, startDate, toDate, apptype?) {
  //   let params = { dateFrom: startDate, dateTo: toDate }
  //   if (apptype) {
  //     params["type"] = apptype
  //   }
  //   return this.http.get(this.baseUrl + `/v1/patient/appointment/${id}`, { params });
  // }

  getAppointmentsByPatientId(id, pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    return this.http.get(this.baseUrl + `/v1/patient/appointment/${id}${query}`);
  }

  saveAppointment(data: any) {
    let url = this.baseUrl + '/v1/patient/appointment';
    return this.http.post(url, data);
  }

  saveMedicalDetails(body: any) {
    let url = this.baseUrl + '/v1/patient/medicalDetails';
    return this.http.post(url, body);
  }
  getAllMedicalDetailsByAuthKey() {
    let url = this.baseUrl + '/v1/patient/getMedicalDetails/byAuthKey';
    return this.http.get(url);
  }
  deleteMedicalDetails(id: any) {
    let url = this.baseUrl + '/v1/patient/deleteMedicalDetails/' + id;
    return this.http.delete(url);
  }

  getAllMedicalDetailsOfLoggedInUser() {
    let url = this.baseUrl + '/v1/patient/getMedicalDetails/latestByAuthKey';
    return this.http.get(url);
  }

  getAllMedicalDetailsLatestsByPatientKey(id) {
    let url = this.baseUrl + '/v1/patient/getMedicalDetails/latest/' + id;
    return this.http.get(url);
  }

  getPrescriptions(id, pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    let url = this.baseUrl + '/v1/patients/prescription/' + id + query;
    return this.http.get(url);
  }

  getPrescriptionById(id) {
    let url = this.baseUrl + '/v1/patients/prescription/Id/' + id;
    return this.http.get(url);
  }

  getPrescriptionPdfById(id) {
    let url = this.baseUrl + '/v1/patients/prescription/pdf/' + id;
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

  getPrescription(pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    let url = this.baseUrl + '/v1/patients/prescription' + query;
    return this.http.get(url);
  }

  getInvoice(pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    let url = this.baseUrl + '/v1/patient/invoice/Details' + query;
    return this.http.get(url);
  }

  getOrder() {
    let url = this.baseUrl + '/v1/patient/order/details';
    return this.http.get(url);
  }

  getOverview(id) {
    let url = this.baseUrl + '/v1/patient/overview/' + id;
    return this.http.get(url);
  }
  getRecentOrders(pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    let url = this.baseUrl + '/v1/patient/order/details' + query;
    return this.http.get(url);
  }


  searchpatientsPharmacy(searchValue) {
    if (searchValue == undefined || searchValue === '') {
      return of([]);
    }

    return this.http.get(this.baseUrl + '/v1/patient/globalSearchPatient?searchKey=' + searchValue)
      .pipe(
        map((response: any) => response.data)
      );
  }
}
