import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgSelect2Module } from 'ng-select2';
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from './shared/shared.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { FileSaverModule } from 'ngx-filesaver';
import { UserService } from './services/user.service';
import { AuthenticationService } from './services/authentication.service';
import { intializeUserService } from './helpers/intializeUserService';
import { intializeAuthService } from './helpers/intializeAuthService';
import { VideoSectionComponent } from './video-section/video-section.component';
import { VideoListComponent } from './video-list/video-list.component';
import { environment } from 'src/environments/environment';
import { initializeApp } from "firebase/app";
import { GlobalSearchComponent } from './common/global-search/global-search.component';
import { CartComponent } from './cart/cart.component';

initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent, ContactUsComponent,
    AppLayoutComponent, VideoSectionComponent, VideoListComponent, GlobalSearchComponent, CartComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Daterangepicker,
    NgSelect2Module,
    NgApexchartsModule,
    SharedModule,
    FileSaverModule
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: intializeUserService, deps: [UserService], multi: true },
    { provide: APP_INITIALIZER, useFactory: intializeAuthService, deps: [AuthenticationService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [AuthenticationService, UserService] }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
