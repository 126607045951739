import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor() { }

  getBloodGroup() {
    let bloodGroups = [
      {
        value: 'A+'
      },
      {
        value: 'A-'
      },
      {
        value: 'O-'
      },
      {
        value: 'O+'
      },
      {
        value: 'B+'
      },
      {
        value: 'B-'
      },
      {
        value: 'AB-'
      },
      {
        value: 'AB+'
      },
    ]
    return of(bloodGroups);
  }

  getRelationships() {
    let relationships = [
      {
        value: 'Father'
      },
      {
        value: 'Mother'
      },
      {
        value: 'Son'
      },
      {
        value: 'Daughter'
      },
      {
        value: 'Grand Father'
      },
      {
        value: 'Grand Mother'
      },
      {
        value: 'Brother'
      },
      {
        value: 'Sister'
      },
      {
        value: 'Uncle'
      },
      {
        value: 'Aunt'
      },
      {
        value: 'Other'
      },
    ]
    return of(relationships);
  }

  getMedicalDetailsTypes() {
    let medicalDetailTypes = [
      {
        value: "Weight"
      },
      {
        value: "Height"
      },
      {
        value: "HeartRate"
      },
      {
        value: "Blood Pressure"
      },
      {
        value: "Body Temperature"
      },
      {
        value: "Glucose"
      },
    ]
    return of(medicalDetailTypes);
  }
  getMedicineIntakeTime() {

    let list = [
      {
        value: 'OD',
        name: 'Once a day'
      },
      {
        value: 'BD',
        name: 'Twice a day'
      },
      {
        value: 'TDS',
        name: 'Three times a day'
      },
      {
        value: 'QID',
        name: 'Four times a day'
      },
      {
        value: 'SOS',
        name: 'If required'
      },
      {
        value: 'HS',
        name: 'Bed time'
      },
      {
        value: 'AC',
        name: 'Before meal'
      },
      {
        value: 'PC',
        name: 'After meal'
      },
      {
        value: '1 TSF',
        name: '5 ml'
      },
    ];

    let timeList = [
      {
        value: '0 - 1 - 1',
        name: '0 - 1 - 1'
      },
      {
        value: '0 - 0 - 1',
        name: '0 - 0 - 1'
      },
      {
        value: '0 - 1 - 0',
        name: '0 - 1 - 0'
      },
      {
        value: '1 - 0 - 0',
        name: '1 - 0 - 0'
      },
      {
        value: '1 - 1 - 1',
        name: '1 - 1 - 1'
      }
    ];

    return of(timeList);
  }

  getSpecialities() {

    let speciality = [
      {
        value: 'Cardiologist',
        image: '../assets/img/icons/specialities-icon/Cardiologist.svg'
      },
      {
        value: 'Dermatologist',
        image: '../assets/img/icons/specialities-icon/Dermatologist.svg'
      },
      {
        value: 'Dentist',
        image: '../assets/img/icons/specialities-icon/Dentist.svg'
      },
      {
        value: 'Diabetologist',
        image: '../assets/img/icons/specialities-icon/Diabetologist.svg'
      },
      {
        value: 'Pulmonologist',
        image: '../assets/img/icons/specialities-icon/Pulmonologist.svg'
      },
      {
        value: 'Ent Surgeon',
        image: '../assets/img/icons/specialities-icon/ENT.svg'
      },
      {
        value: 'Endocrinologist',
        image: '../assets/img/icons/specialities-icon/Endocrinologist.svg'
      },
      {
        value: 'Gastroenterologist',
        image: '../assets/img/icons/specialities-icon/Gastroenterologist.svg'
      },
      {
        value: 'Gynaecologist',
        image: '../assets/img/icons/specialities-icon/Gynacologist.svg'
      },
      {
        value: 'General Physician',
        image: '../assets/img/icons/specialities-icon/GeneralPhysician.svg'
      },
      {
        value: 'Oncologist',
        image: '../assets/img/icons/specialities-icon/Oncologist.svg'
      },
      {
        value: 'Ophthalmologist',
        image: '../assets/img/icons/specialities-icon/Ophthalmologist.svg'
      },
      {
        value: 'Orthopaedic',
        image: '../assets/img/icons/specialities-icon/Orthopedic.svg'
      },
      {
        value: 'Pediatrician',
        image: '../assets/img/icons/specialities-icon/Pediatrician.svg'
      },
      {
        value: 'Physiotherapist',
        image: '../assets/img/icons/specialities-icon/Physiotherapist.svg'
      },
      {
        value: 'Psychiatrist',
        image: '../assets/img/icons/specialities-icon/Psychiatrist.svg'
      },
      {
        value: 'Urologist',
        image: '../assets/img/icons/specialities-icon/Urology.svg'
      },
      {
        value: 'Rheumatologist',
        image: '../assets/img/icons/specialities-icon/Rheumatologist.svg'
      },
      {
        value: 'Sexologist',
        image: '../assets/img/icons/specialities-icon/Sexologist.svg'
      },
      {
        value: 'Nephrologist',
        image: '../assets/img/icons/specialities-icon/Nephrologist.svg'
      },
    ];
    return of(speciality);
  }
}

