<div class="row justify-content-center m-0 footer py-5">
  <div class="col-md-11">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="footer-widget footer-menu">
          <span class="footer-title">For Patients</span>
          <ul class="mt-3">
            <li [routerLink]="'/patients/search-doctor'" class="footer-desc">Search for Doctors</li>
            <li [routerLink]="'/login'" class="footer-desc">Login</li>
            <li [routerLink]="'/Register'" class="footer-desc">Register</li>
            <li [routerLink]="'/patients/booking'" class="footer-desc">Booking</li>
            <li [routerLink]="'/patients/dashboard'" class="footer-desc">Patient Dashboard</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-3 col-md-6">
        <div class="footer-widget footer-menu">
          <span class="footer-title">For Doctors</span>
          <ul class="mt-3">
            <li [routerLink]="'/doctor/appointment'" class="footer-desc">Appointments</li>
            <li [routerLink]="'/doctor/message'" class="footer-desc">Chat</li>
            <li [routerLink]="'/login'" class="footer-desc">Login</li>
            <li [routerLink]="'/Register'" class="footer-desc">Register</li>
            <li [routerLink]="'/doctor/dashboard'" class="footer-desc">Doctor Dashboard</li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="footer-widget footer-contact">
          <span class="footer-title">Contact Us</span>
          <div class="footer-contact-info mt-3">
            <div class="footer-desc">
              <p> 3556 Beech Street, San Francisco,<br> California, CA 94108 </p>
            </div>
            <p class="footer-desc">
              +1 315 369 5943
            </p>
            <p class="mb-0 footer-desc">
              support@eazyop.in
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-6">
        <div class="footer-widget footer-menu">
          <span class="footer-title">Social</span>
          <ul class="mt-3">
            <li class="footer-desc">LinkedIn</li>
            <li class="footer-desc">Facebook</li>
            <li class="footer-desc">Twitter</li>
            <li class="footer-desc">YouTube</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footer-bottom pt-5">
      <div class="row">
        <div class="col-md-6 col-lg-6">
          <p class="mb-0 copyright-text">© 2022 EazyOp. All rights reserved.</p>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="copyright-menu">
            <ul class="policy-menu">
              <li [routerLink]="'/terms-conditions'" class="copyright-text">Terms and Conditions</li>
              <li [routerLink]="'/privacy-policy'" class="copyright-text">Policy</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>