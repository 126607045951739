import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/services/clinic.service';
import { DoctorService } from 'src/app/services/doctor.service';
import { PatientService } from 'src/app/services/patient.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-add-prescription',
  templateUrl: './add-prescription.component.html',
  styleUrls: ['./add-prescription.component.scss']
})
export class AddPrescriptionComponent implements OnInit {

  submitted = false;
  appointmentId = undefined;
  clinicId = '';
  patientId = '';
  doctorId = '';
  prescriptionForm: FormGroup;
  prescription: FormArray;
  patientDetails;
  doctorDetails;
  intakeTimeList = [];


  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private productService: ProductService,
    private patientService: PatientService,
    private clinicService: ClinicService,
    private doctorService: DoctorService,
    private toastr: ToastrService,
    private staticDataService: StaticDataService,
    private route: ActivatedRoute,
    private router: Router) { }


  get f() { return this.prescriptionForm.controls; }

  ngOnInit(): void {

    this.clinicService.getSelectedClinic()
      .subscribe((response: any) => {
        this.clinicId = response?.clinicId;
      });

    this.prescriptionForm = this.formBuilder.group({
      prescription: new FormArray([]),
      notes: [''],
    });
    this.addPrescription();

    this.staticDataService.getMedicineIntakeTime()
      .subscribe(response => {
        this.intakeTimeList = response;
      })

    if (this.patientId && this.doctorId) {
      this.getPatientDetails(this.patientId);
      this.getDoctorsDetails(this.doctorId);
    }
  }

  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((searchText) =>
        this.productService.getProducts(this.clinicId, 1, searchText)),
      catchError(() => {
        return of([]);
      })
    );

  formatter = (result: any) => {
    let value = result.name;
    if (result.MFR) {
      value = value + ' - ' + result.MFR;
    }
    return value;
  }

  onSubmit() {
    this.submitted = true;
    if (this.prescriptionForm.invalid) {
      return;
    }

    // this.spinner.show();

    // let request = {
    //   docKey: this.doctorDetails._id,
    //   patientKey: this.patientDetails._id,
    //   clinicKey: this.clinicId,
    //   appointmentKey: this.appointmentId,
    //   digitallySigned: this.doctorDetails._id,
    //   notes: this.prescriptionForm.value.notes,
    //   prescriptionType: 'Digital',
    //   productDetails: []
    // }
    const formData = new FormData();
    formData.append('docKey', this.doctorDetails._id);
    formData.append('patientKey', this.patientDetails._id);
    formData.append('clinicKey', this.clinicId);

    if (this.appointmentId) {
      formData.append('appointmentKey', this.appointmentId);
    }

    formData.append('digitallySigned', this.doctorDetails._id);
    formData.append('notes', this.prescriptionForm.value.notes);
    formData.append('prescriptionType', "Digital");

    let productDetails = this.prescriptionForm.value.prescription;

    if (productDetails) {
      let meds = [];
      productDetails.forEach(md => {
        let med = {
          name: md.name,
          quantity: md.quantity,
          days: md.days,
          notes: md.notes,
          time: md.time1 + ' ' + md.time2
        };
        meds.push(med);
      });
      formData.append('productDetails', JSON.stringify(meds));
    }

    this.doctorService.createPrescription(formData)
      .subscribe(res => {
        this.toastr.success('Prescription added');
        this.spinner.hide();
        this.activeModal.dismiss('success');
        this.router.navigate(['../../../doctor/patient-profile/' + this.patientDetails._id], { relativeTo: this.route });
      }, err => {
        this.toastr.error('Error occurred while creating prescription');
        this.spinner.hide();
      });
  }

  onReset() {
    this.submitted = false;
    this.prescriptionForm.reset();
  }

  getPatientDetails(patientId) {
    this.spinner.show();
    this.patientService.getPatientProfileById(patientId)
      .subscribe((response: any) => {
        this.patientDetails = response.data;
        this.calculateAge();
        this.spinner.hide();
      }, err => {
        this.toastr.error('Error occurred while fetching patient information');
        this.spinner.hide();
      });
  }

  calculateAge(): void {
    this.patientDetails.age = '';
    if (this.patientDetails.dob != undefined) {
      let dobDt = new Date(this.patientDetails.dob);
      let timeDiff = Math.abs(Date.now() - dobDt.getTime());
      this.patientDetails.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
  }

  getDoctorsDetails(doctorId) {
    this.doctorService.getDoctorPublicProfile(doctorId)
      .subscribe((res: any) => {
        this.doctorDetails = res.data.profile;
      });
  }

  createPrescription(): FormGroup {
    return this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      quantity: ['', Validators.required],
      days: ['', Validators.required],
      notes: [''],
      time1: ['', Validators.required],
      time2: ['', Validators.required]
    });
  }

  deletePrescription(index: number) {
    const add = this.prescriptionForm.get('prescription') as FormArray;
    add.removeAt(index)
  }

  addPrescription(): void {
    this.prescription = this.prescriptionForm.get('prescription') as FormArray;
    this.prescription.push(this.createPrescription());
  }

  selectedItem(selectedValue, index) {
    const prescription = this.prescriptionForm.get('prescription') as FormArray;

    if (selectedValue.item.name) {
      let value = selectedValue.item.name;
      if (selectedValue.item.MFR) {
        value = value + ' - ' + selectedValue.item.MFR;
      }

      prescription.value[index].name = value;
    }
    else {
      prescription.value[index].name = selectedValue;
    }

    this.prescriptionForm.get('prescription').patchValue(prescription.value);
  }

}
