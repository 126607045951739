import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {
  private pharmacySubject = new BehaviorSubject<any>({});

  public baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  setSelectedPharmacy(selectedPharmacy) {
    this.pharmacySubject.next(selectedPharmacy);
  }

  getSelectedPharmacy() {
    return this.pharmacySubject.asObservable();
  }

  getPharmacy(pharmacyKey: any, isAppType: any, selectedClinic: any) {
    if (isAppType == 1) {
      let url = this.baseUrl + '/v1/pharmacyStore/getDoctorPrivatePharmacyStoreByPharmacyKey/' + pharmacyKey + '/' + selectedClinic;
      return this.http.get(url);
    } else {
      let url = this.baseUrl + '/v1/pharmacyStore/getPrivatePharmacyStoreByPharmacyKey/' + pharmacyKey;
      return this.http.get(url);
    }

  }
  savePharmacyStore(data: any, pharmacyKey: any) {
    let url = this.baseUrl + '/v1/pharmacyStore/create/' + pharmacyKey;
    return this.http.post(url, data);
  }
  updatePharmacyStore(pharmacyKey: any, data: any) {
    let url = this.baseUrl + '/v1/pharmacyStore/update/' + pharmacyKey;
    return this.http.put(url, data);
  }
  deletePharmacy(pharmacyId: any) {
    let url = this.baseUrl + '/v1/pharmacyStore/delete/' + pharmacyId;
    return this.http.delete(url);
  }
  getPharmacyById(pharmacyStoreKey: any) {
    let url = this.baseUrl + '/v1/pharmacyStore/getPharmacyStoreById/' + pharmacyStoreKey;
    return this.http.get(url);
  }


}

