import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { Event, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { CommonServiceService } from './../../common-service.service';
import { ClinicSelectionModalComponent } from 'src/app/doctor/clinic-selection-modal/clinic-selection-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ClinicService } from 'src/app/services/clinic.service';
import { PatientsChangePasswordComponent } from 'src/app/patients/patients-change-password/patients-change-password.component';
import { DoctorChangePasswordComponent } from 'src/app/doctor/doctor-change-password/doctor-change-password.component';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { AppType } from 'src/app/models/app-type';
import { PharmacySelectionModalComponent } from 'src/app/pharmacy/pharmacy-selection-modal/pharmacy-selection-modal.component';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  auth: boolean = false;
  appType: AppType;
  page;
  splitVal;
  base;
  notificationMessages = [];
  selectedClinic: any;
  selectedPharmacy: any;
  user: any;
  initializeNotification = false;
  name;
  cartCount: any;

  private tokenValue = '';

  constructor(
    @Inject(DOCUMENT) private document,
    private cdr: ChangeDetectorRef,
    public router: Router,
    public commonService: CommonServiceService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private clinicService: ClinicService,
    private pharmacyService: PharmacyService,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationsService,
    private toastr: ToastrService,
    public cartService: CartService
  ) {
    router.events.subscribe((event: Event) => {
      this.auth = this.userService.isUserLoggedIn();
      this.appType = this.userService.getAppType();
      if (this.auth) {
        this.setUpNotification();
      }
    });
    this.commonService.message.subscribe((res) => {
      this.auth = this.userService.isUserLoggedIn();
      this.appType = this.userService.getAppType();
    });
  }

  public get appTypes(): typeof AppType {
    return AppType;
  }

  ngOnInit(): void {
    // Sidebar
    if ($(window).width() <= 991) {
      var Sidemenu = function () {
        this.$menuItem = $('.main-nav a');
      };
      function init() {
        var $this = Sidemenu;
        $('.main-nav a').on('click', function (e) {
          if ($(this).parent().hasClass('has-submenu')) {
            e.preventDefault();
          }
          if (!$(this).hasClass('submenu')) {
            $('ul', $(this).parents('ul:first')).slideUp(350);
            $('a', $(this).parents('ul:first')).removeClass('submenu');
            $(this).next('ul').slideDown(350);
            $(this).addClass('submenu');
          } else if ($(this).hasClass('submenu')) {
            $(this).removeClass('submenu');
            $(this).next('ul').slideUp(350);
          }
        });
      }

      // Sidebar Initiate
      init();
    }

    this.auth = this.userService.isUserLoggedIn();
    this.appType = this.userService.getAppType();
    if (this.auth && this.appType == AppType.Doctor) {
      this.getDoctorNotifications();
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $('html').removeClass('menu-opened');
        $('.sidebar-overlay').removeClass('opened');
        $('.main-wrapper').removeClass('slide-nav');
      }
    });

    if (this.appType == AppType.Doctor) {
      this.clinicService.getSelectedClinic()
        .subscribe(response => {
          this.selectedClinic = response;
        });
    }
    if (this.appType == AppType.Pharmacy) {
      this.pharmacyService.getSelectedPharmacy()
        .subscribe(response => {
          if (response && response._id) {
            this.selectedPharmacy = response;
          } else {
            this.selectedPharmacy = JSON.parse(localStorage.getItem('selectedPharmacy'));
          }
        });
    }


    // this.user = this.userService.getUser();

    this.userService.getUserObservable()
      .subscribe(u => {
        this.user = u;
        this.getCartItemCount(this.user.id);
      })

      this.cartService.dataAddtoCart$.subscribe(data => {
        this.getCartItemCount(this.user.id);
      });
  }

  getDoctorNotifications() {
    this.spinner.show();
    if (this.appType == AppType.Doctor) {
      this.notificationService.getDoctorNotifications()
        .subscribe((response: any) => {
          if (response?.data?.details) {
            this.notificationMessages = response.data.details;
          }
          this.spinner.hide();
        }, err => {
          this.spinner.hide();
          this.toastr.error('Error occured while fetching notifications');
        });
    }
  }


  ngAfterViewChecked() {
    if (this.appType == AppType.Pharmacy) {
      this.pharmacyService.getSelectedPharmacy()
        .subscribe(response => {
          if (response && response._id) {
            this.selectedPharmacy = response;
          } else {
            this.selectedPharmacy = JSON.parse(localStorage.getItem('selectedPharmacy'));
          }
        });
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.loadDynmicallyScript('assets/js/script.js');
  }

  openClinicSelectionModal() {
    const modalRef = this.modalService.open(ClinicSelectionModalComponent, { size: 'lg' });
  }

  openPharmacySelectionModal() {
    const modalRef = this.modalService.open(PharmacySelectionModalComponent, { size: 'lg' });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
    // this.deleteToken()
    //   .subscribe(response => {
    //     debugger;
    //     this.authenticationService.logout();
    //     this.router.navigate(['/']);
    //   });
  }

  loadDynmicallyScript(js) {
    var script = document.createElement('script');
    script.src = js;
    script.async = false;
    document.head.appendChild(script);
    script.onload = () => this.doSomethingWhenScriptIsLoaded();
  }
  doSomethingWhenScriptIsLoaded() { }
  change(name) {
    this.page = name;
    this.commonService.nextmessage('main');
  }

  mapGrid() {
    this.router.navigate(['/map-grid']);
  }

  mapList() {
    this.router.navigate(['/map-list']);
  }

  addStyle(val) {
    if (val === 'admin') {
      if (document.getElementById('admin').style.display == 'block') {
        document.getElementById('admin').style.display = 'none';
      } else {
        document.getElementById('admin').style.display = 'block';
      }
    }
  }

  doctor(name) {
    this.page = name;
    // this.router.navigate(['/doctor/dashboard']);
  }


  home() {
    this.commonService.nextmessage('main');
    this.router.navigateByUrl('/').then(() => {
      this.router.navigate(['/']);
    });
  }

  navigate(name) {
    this.page = name;
  }


  openChangepassPatientModal() {
    const modalRef = this.modalService.open(PatientsChangePasswordComponent, { size: 'md' });
  }
  openChangepassDoctorModal() {
    const modalRef = this.modalService.open(DoctorChangePasswordComponent, { size: 'md' });
  }

  setUpNotification() {
    if (!this.initializeNotification) {
      this.requestPermission();
      this.listen();
      this.initializeNotification = true;
    }
  }

  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging,
      { vapidKey: environment.firebase.vapidKey }).then(
        (currentToken) => {
          if (currentToken) {
            this.tokenValue = currentToken;
            console.log(currentToken);
            if (this.appType == AppType.Patient) {
              this.notificationService.storePatientFireBaseToken(currentToken)
                .subscribe(response => {

                });
            }
            else if (this.appType == AppType.Doctor) {
              this.notificationService.storeDoctorFireBaseToken(currentToken)
                .subscribe(response => {

                });
            }

          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.notificationMessages.unshift(payload.notification);
    });

  }

  ngOnDestroy(): void {
    this.deleteToken()
      .subscribe(response => {

      });;
  }

  private deleteToken() {
    if (this.tokenValue) {
      if (this.appType == AppType.Patient) {
        return this.notificationService.deletePatientFireBaseToken(this.tokenValue);
      }
      else if (this.appType == AppType.Doctor) {
        return this.notificationService.deleteDoctorFireBaseToken(this.tokenValue);
      }
    }
    return of();
  }

  getCartItemCount(id) {
    this.cartService.getCartItemsByPatient(id).subscribe((response: any) => {
      if(response && response.data.medicines) {
        this.cartCount = response.data.medicines.medicine.length;
      }
    })
  }
}
