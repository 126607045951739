<div class="container-fluid bg-light-blue py-5">
</div>
<div class="container-fluid py-5">
    <div class="row justify-content-center m-0">
        <div class="col-md-11">
            <video class="w-100" height="450" controls class="video-container w-100">
                <source src="https://www.youtube.com/watch?v=UQH3GWsCmr8" type="video/mp4">
            </video>
            <h2 class="title py-3">The Life and Career of Steve Charles, MD, FACS,<br> FICS, FASRS</h2>
            <p class="video-desc">In this episode, John W. Kitchens, MD, speaks with Steve Charles, MD, FACS, FICS,
                FASRS, a vitreoretinal<br>
                surgeon and founder of the Charles Retina Institute in Germantown, Tennessee, as well as clinical<br>
                professor of ophthalmology at the University of Tennessee in Memphis</p>
            <p class="video-desc mt-3">Known to many as the architect behind the ACCURUS and CONSTELLATION from Alcon,
                his career is <br>
                wildly impressive, but Dr. Kitchens finds his life and career history even more fascinating.
            </p>
        </div>
    </div>
</div>