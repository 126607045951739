import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PharmacyService } from 'src/app/services/pharmacy.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-pharmacy-selection-modal',
  templateUrl: './pharmacy-selection-modal.component.html',
  styleUrls: ['./pharmacy-selection-modal.component.scss']
})
export class PharmacySelectionModalComponent implements OnInit {
  pharmacyList: any = [];
  selectedPharmacy: any;
  selectedClinic: any;
  constructor(public activeModal: NgbActiveModal,
    private userService: UserService,
    private pharmacyService: PharmacyService) { }

  ngOnInit(): void {
    if(localStorage.getItem('clinicId')) {
      this.selectedClinic = localStorage.getItem('clinicId');
    }
    this.getPharmacies();
  }

  getPharmacies() {
    let impersonateId = this.userService.getImpersonateId();
    let isAppType = this.userService.getAppType();
    this.pharmacyService.getPharmacy(impersonateId, isAppType, this.selectedClinic)
      .subscribe((response: any) => {
        this.pharmacyList = response.data;
        if(this.pharmacyList.length > 0) {
          this.selectedPharmacy = this.pharmacyList[0];
          localStorage.setItem('pharmacyId', this.selectedPharmacy._id);
          localStorage.setItem('selectedPharmacy', JSON.stringify(this.selectedPharmacy));
        }
        
      }, err => {
        
      });
  }

  selectPharmacy(pharmacy) {
    this.selectedPharmacy = pharmacy;
    this.pharmacyService.setSelectedPharmacy(this.selectedPharmacy);
    localStorage.setItem('pharmacyId', this.selectedPharmacy._id);
    localStorage.setItem('selectedPharmacy', JSON.stringify(this.selectedPharmacy));
    this.activeModal.dismiss();
  }

}
