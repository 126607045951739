import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getProducts(clinicId, pageNo, searchValue): Observable<any> {
    let pageSize = 10;

    let url = this.baseUrl + '/v1/clinic/' + clinicId + '/products?pageNumber='
      + pageNo + '&pageSize=' + pageSize;

    if (searchValue) {
      url = url + '&searchValue=' + searchValue;
    }

    return this.http.get(url).pipe(
      map((response: any) => {
        console.log(response);
        let products = response.data.products;
        if (products.length == 0) {
          products = [
            {
              name: searchValue
            }
          ]
        }
        return products;
      })
    );
  }


  getProductsInventory(clinicId, pageNo, searchValue): Observable<any> {
    let pageSize = 10;

    let url = this.baseUrl + '/v1/clinic/' + clinicId + '/products?pageNumber='
      + pageNo + '&pageSize=' + pageSize;

    if (searchValue) {
      url = url + '&searchValue=' + searchValue;
    }

    return this.http.get(url);
  }

  getProductsListByPharmayStore(pharmacyStoreId, pageNo, searchValue): Observable<any> {
    let pageSize = 10;

    let url = this.baseUrl + '/v1/pharmacy/' + pharmacyStoreId + '/products?pageNumber='
      + pageNo + '&pageSize=' + pageSize;

    if (searchValue) {
      url = url + '&searchValue=' + searchValue;
    }

    return this.http.get(url);
  }

  uploadProducts(request) {
    return this.http.post(this.baseUrl + '/v1/pharmacy/product-import', request);
  }

  deletePharmacyProducts(pharmacyID : any){
    return this.http.delete(this.baseUrl + '/v1/pharmacy/deleteProductRecord/'+pharmacyID);
  }

  deleteOnePharmacyProduct(pharmacyID : any, productID : any){
    return this.http.delete(this.baseUrl + '/v1/pharmacy/deleteOneByPharmacyIdAndProductId/'+pharmacyID+'/'+productID);
  }

  addOnePharmacyProduct(pharmacyStoreID: any , formData : any){
    return this.http.post(this.baseUrl + '/v1/pharmacy/createOneByPharmacyId/'+pharmacyStoreID , formData);
  }

  updateOnePharmacyProduct(pharmacyStoreID: any ,productId : any, formData : any){
    console.log(formData);
    return this.http.post(this.baseUrl + '/v1/pharmacy/updateOneByPharmacyIdAndMedicineId/'+pharmacyStoreID+'/'+productId , formData);
  }

    // /updateOneByPharmacyIdAndMedicineId/:pharmacyId/:productId()
}
