import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-consultation-invoice',
  templateUrl: './consultation-invoice.component.html',
  styleUrls: ['./consultation-invoice.component.scss']
})
export class ConsultationInvoiceComponent implements OnInit {
  @Input() invoice;

  constructor(
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }
}
