import { AuthenticationService } from "../services/authentication.service";

export function intializeAuthService(authenticationService: AuthenticationService) {
  return (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }
}
