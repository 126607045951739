import { UserService } from "../services/user.service";

export function intializeUserService(userService: UserService) {
  return (): Promise<void> => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 100);
    });
  }
}
