<div class="container-fluid py-5 bg-contact">
  <div class="row">
    <div class="col-md-6">
    </div>
    <div class="col-md-6">
      <form [formGroup]="contactUsForm" (ngSubmit)="onSubmit()">
        <div class="card border-rad">
          <div class="card-body p-5">
            <h4 class="card-title">Please provide details to contact you</h4>
            <div class="row form-row">
              <div class="col-md-12">
                <div class="form-group">
                  <select class="form-control select" formControlName="concern"
                    [ngClass]="{ 'is-invalid': f.concern.touched && f.concern.errors }">
                    <option value="">Select your concern</option>
                    <option>Health Check</option>
                    <option>Lab Test Order</option>
                    <option>Medicine Order</option>
                    <option>Consultation</option>
                    <option>App Issues</option>
                    <option>Coupons</option>
                    <option>Subscriptions</option>
                    <option>Account and Profile Settings</option>
                    <option>Payment and Refunds</option>
                    <option>Security</option>
                  </select>
                  <div *ngIf="f.concern.touched && f.concern.errors" class="invalid-feedback">
                    <div>field is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <select class="form-control select" formControlName="issues"
                    [ngClass]="{ 'is-invalid': f.issues.touched && f.issues.errors }">
                    <option value="">Select the concern you are facing</option>
                    <option>I am unable to make payments</option>
                    <option>I have not recieved my refund</option>
                    <option>My concern is not listed</option>
                  </select>
                  <div *ngIf="f.issues.touched && f.issues.errors" class="invalid-feedback">
                    <div>field is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Name<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="firstName"
                    [ngClass]="{ 'is-invalid': f.firstName.touched && f.firstName.errors }">
                  <div *ngIf="f.firstName.touched && f.firstName.errors?.required" class="invalid-feedback">
                    <div>Name is required</div>
                  </div>
                  <div class="invalid-feedback" *ngIf="f.firstName.touched && f.firstName.errors?.minlength">
                    <div>Name must be 2 characters long</div>
                  </div>
                  <div class="invalid-feedback" *ngIf="f.firstName.touched && f.firstName.errors?.pattern">
                    <div>No special charcter allowed</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Phone Number<span class="text-danger">*</span></label>
                  <input type="text" class="form-control" formControlName="phoneNumber"
                    [ngClass]="{ 'is-invalid': f.phoneNumber.touched  && f.phoneNumber.errors }">
                  <div *ngIf="f.phoneNumber.touched && f.phoneNumber.errors?.required" class="invalid-feedback">
                    <div>Phone Number is required</div>
                  </div>
                  <div *ngIf="f.phoneNumber.touched && f.phoneNumber.errors?.pattern" class="invalid-feedback">
                    <div>number must be 10 digit long</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Email<span class="text-danger">*</span></label>
                  <input type="email" class="form-control" formControlName="email"
                    [ngClass]="{ 'is-invalid': f.email.touched && f.email.errors }">
                  <div class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Explain your concern <span class="text-danger">*</span></label>
                  <textarea type="text" class="form-control" formControlName="explain"
                    [ngClass]="{ 'is-invalid': f.explain.touched && f.explain.errors }" rows="6"></textarea>
                  <div *ngIf="f.explain.touched && f.explain.errors?.required" class="invalid-feedback">
                    <div>field is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="submit-section text-center">
                  <button type="submit" class="btn btn-orange submit-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>