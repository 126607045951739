import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DoctorService {

  public baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {

  }

  getpatients() {
    return this.http.get(this.baseUrl + '/v1/doctor/myPatients');
  }

  searchpatients(searchValue) {
    if (searchValue == undefined || searchValue === '') {
      return of([]);
    }

    return this.http.get(this.baseUrl + '/v1/doctor/myPatients?searchKey=' + searchValue)
      .pipe(
        map((response: any) => response.data)
      );
  }


  getAppointments(id, clinicId, startDate, endDate, pageNo, pageSize = 10) {
    let params = { clinicId: clinicId, dateFrom: startDate, dateTo: endDate, pageSize: pageSize, pageNumber: pageNo }
    return this.http.get(this.baseUrl + '/v1/doctor/appointment/' + id, { params });
  }

  getAllAppointmentsOfDoctor(startDate, endDate, clinicId, type?) {
    let params = { dateFrom: startDate, dateTo: endDate, clinicId: clinicId }
    if (type) {
      params["appointmentType"] = type
    }

    return this.http.get(this.baseUrl + '/v1/doctor/getAppointmentsByDocAuthKey', { params })
  }

  updateAppointment(data, id) {
    // return this.http.put(`${this.baseUrl + 'appointments'}/${id}`, data);
    return of(true);
  }

  getDoctorProfile() {
    return this.http.get(this.baseUrl + '/v1/doctor/profile');
  }

  getDoctorPublicProfile(id: any) {
    return this.http.get(this.baseUrl + '/v1/doctor/profile/' + id);
  }

  getDoctorReviews(docId: any) {
    return this.http.get(this.baseUrl + '/v1/doctor/reviews/' + docId);
  }
  getDoctorReviewsByAuthKey() {
    return this.http.get(this.baseUrl + '/v1/doctor/reviewsByDocAuthKey');
  }


  getClinicManager(clinicKey: any) {
    return this.http.get(this.baseUrl + '/v1/auth/doctor/clinicManagerByClinicKey/' + clinicKey);
  }

  createClinicManager(body: any) {
    let url = this.baseUrl + '/v1/auth/doctor/register/clinicManager';
    return this.http.post(url, body);
  }
  deleteClinicManager(id: any) {
    let url = this.baseUrl + '/v1/auth/doctor/clinicManager/' + id;
    return this.http.delete(url);
  }

  createPrescription(request) {
    let url = this.baseUrl + '/v1/doctor/prescription';
    return this.http.post(url, request);
  }

  createBilling(request) {
    let url = this.baseUrl + '/v1/doctor/invoice';
    return this.http.post(url, request)
  }

  updateClinicManager(id: any) {
    let url = this.baseUrl + '/v1/auth/doctor/clinicManager/' + id;
    return this.http.put(url, id);
  }

  updateDoctorProfile(body: any) {
    let url = this.baseUrl + '/v1/doctor/profile-update';
    return this.http.put(url, body);
  }

  getBillings(id, pageNo) {
    let query = "?pageSize=10&pageNo=" + pageNo;
    let url = this.baseUrl + '/v1/doctor/invoice/patient/' + id + query;
    return this.http.get(url);
  }
  getBillingById(id) {
    let url = this.baseUrl + '/v1/doctor/invoice/' + id;
    return this.http.get(url);
  }

  getOrders(id) {
    let url = this.baseUrl + '/v1/doctor/order/clinic/' + id;
    return this.http.get(url);
  }

  getInvoicePdfById(id) {
    let url = this.baseUrl + '/v1/doctor/invoicePDF/' + id;
    return this.http.get(url, { responseType: 'blob' as 'json' });
  }

}

