import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { RegisterSuccessfullyComponent } from './doctor/register/register-successfully/register-successfully.component';

const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home'
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      { path: 'doctor-register/success', component: RegisterSuccessfullyComponent },
      {
        path: 'doctor',
        loadChildren: () =>
          import('./doctor/doctor.module').then((m) => m.DoctorModule),
      },
      {
        path: 'pharmacy',
        loadChildren: () =>
          import('./pharmacy/pharmacy.module').then(
            (m) => m.PharmacyModule
          )
      },
      {
        path: 'patients',
        loadChildren: () =>
          import('./patients/patients.module').then((m) => m.PatientsModule)
      },
      {
        path: 'video',
        loadChildren: () =>
          import('./video-section/video-section.module').then((m) => m.VideoSectionModule),
      },
      {
        path: 'video-list',
        loadChildren: () =>
          import('./video-list/video-list.module').then((m) => m.VideoListModule),
      },
      {
        path: 'view-pharmacy',
        loadChildren: () =>
          import('./view-all-nearby-pharmacy/view-all-nearby-pharmacy.module').then((m) => m.ViewAllNearbyPharmacyModule),
      },
      {
        path: 'view-best-clinics',
        loadChildren: () =>
          import('./view-all-nearby-clinics/view-all-nearby-clinics.module').then((m) => m.ViewAllNearbyClinicsModule),
      },
      {
        path: 'consult-list',
        loadChildren: () =>
          import('./speciality-list/speciality-list.module').then((m) => m.SpecialityListModule),
      },
      {
        path: 'map-grid',
        loadChildren: () =>
          import('./map-grid/map-grid.module').then((m) => m.MapGridModule),
      },
      {
        path: 'map-list',
        loadChildren: () =>
          import('./map-list/map-list.module').then((m) => m.MapListModule),
      },
      {
        path: 'calender',
        loadChildren: () =>
          import('./calender/calender.module').then((m) => m.CalenderModule),
      },
      {
        path: 'invoice-details',
        loadChildren: () =>
          import('./invoice-details/invoice-details.module').then((m) => m.InvoiceDetailsModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
      },
      {
        path: 'terms-conditions',
        loadChildren: () =>
          import('./terms-conditions/terms-conditions.module').then((m) => m.TermsConditionsModule),
      },
      {
        path: 'contact-us',
        loadChildren: () =>
          import('./contact-us/contact-us.module').then((m) => m.ContactUsModule),
      },

      {
        path: 'login-page',
        loadChildren: () =>
          import('./login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./register/register.module').then((m) => m.RegisterModule),
      },
      {
        path: 'doctor-register',
        loadChildren: () => import('./doctor/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'doctor-login',
        loadChildren: () => import('./doctor/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'doctor-forgot-password',
        loadChildren: () => import('./doctor/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      {
        path: 'pharmacy-login',
        loadChildren: () => import('./pharmacy/login/login.module').then(m => m.LoginModule)
      },
      {
        path: 'pharmacy-register',
        loadChildren: () => import('./pharmacy/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'pharmacy-forgot-password',
        loadChildren: () => import('./pharmacy/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
      },
      { path: 'search-medicine-home', loadChildren: () => import('./search-medicine-home/search-medicine-home.module').then(m => m.SearchMedicineHomeModule) },
      { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) }
    ]
  },
  { path: '*', redirectTo: '' },
  
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
