import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DoctorService } from 'src/app/services/doctor.service';


@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit {

  model: any;
  searching = false;
  searchFailed = false;

  constructor(private doctorService: DoctorService,
    private router: Router) { }

  ngOnInit(): void {
  }

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     tap(() => this.searching = true),
  //     switchMap(term =>
  //       this.doctorService.searchpatients(term).pipe(
  //         tap(() => this.searchFailed = false),
  //         catchError(() => {
  //           this.searchFailed = true;
  //           return of([]);
  //         }))
  //     ),
  //     tap(() => this.searching = false)
  //   );


  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term) =>
        this.doctorService.searchpatients(term)),
      catchError(() => {
        return of([]);
      })
    );


  formatter = (result: any) => {
    console.log(result);
    return result.firstName + ' ' + result.lastName;
  }


  selectedItem(selectedValue) {
    console.log(selectedValue.item);
    this.router.navigate(['/doctor/patient-profile/' + selectedValue.item._id]);
  }

}
