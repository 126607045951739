import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientsChangePasswordComponent } from './patients-change-password.component';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [PatientsChangePasswordComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class PatientsChangePasswordModule { }
