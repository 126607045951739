<div class="row justify-content-center download-section m-0">
    <div class="col-md-11">
        <div class="row mt-4">
            <div class="col-md-6">
                <p class="download-title m-0">Download the EAZY OP</p>
                <p class="download-title m-0">APP</p>
                <p class="download-checks mt-3"><i class="fas fa-check px-1"></i>
                    Book Doctor Appointment
                </p>
                <p class="download-checks"><i class="fas fa-check px-1"></i>
                    Consult Doctor
                </p>
                <p class="download-checks"><i class="fas fa-check px-1"></i>
                    Store Prescription
                </p>
                <p class="download-checks"><i class="fas fa-check px-1"></i>
                    Store Health Record
                </p>
                <div class="row d-flex mt-5">
                    <div class="text-center pl-3">
                        <img src="../../../assets/img/google-play-badge.svg">
                    </div>
                    <div class="text-center">
                        <img src="../../../assets/img/app-store-badge.svg" class="px-2">
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="d-flex w-50">
                    <img src="../../../assets/img/download-app1.svg" class="w-100">
                    <img src="../../../assets/img/download-app2.svg" class="w-100 ml-5">
                </div>
            </div>
        </div>
    </div>
</div>