import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  private clinicSubject = new BehaviorSubject<any>({});

  public baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {

  }

  setSelectedClinic(selectedClinic) {
    this.clinicSubject.next(selectedClinic);
  }

  getSelectedClinic() {
    return this.clinicSubject.asObservable();
  }

  saveClinic(request: any, doctorId: any) {
    return this.http.post(this.baseUrl + '/v1/doctor/clinic/' + doctorId, request);
  }

  updateClinic(id, request: any) {
    return this.http.put(this.baseUrl + '/v1/doctor/clinic/' + id, request);
  }

  getClinic(id) {
    return this.http.get(this.baseUrl + '/v1/doctor/clinic/' + id);
  }

  getAllClinics(docId) {
    return this.http.get(this.baseUrl + '/v1/doctor/clinics/' + docId);
  }

  getLocations() {
    return this.http.get(this.baseUrl + '/v1/doctor/location');
  }

  deleteClinic(id) {
    return this.http.delete(this.baseUrl + '/v1/doctor/clinic/' + id);
  }

  getClinicSettings(id) {
    return this.http.get(this.baseUrl + '/v1/clinic/' + id + '/setting');
  }

  saveClinicSettings(id, key, value) {
    let request = {
      key: key,
      value: value
    };
    return this.http.post(this.baseUrl + '/v1/clinic/' + id + '/setting', request);
  }

}
