import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbActiveModal, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelect2Module } from 'ng-select2';
import { SortByPipe } from './sort-by.pipe';
import { DoctorChangePasswordModule } from '../doctor/doctor-change-password/doctor-change-password.module';
import { PatientsChangePasswordModule } from '../patients/patients-change-password/patients-change-password.module';
import { DownloadEazyopModule } from './download-eazyop/download-eazyop.module';
import { MedicineInvoiceComponent } from './medicine-invoice/medicine-invoice.component';
import { ConsultationInvoiceComponent } from './consultation-invoice/consultation-invoice.component';
import { AddPrescriptionModule } from '../patients/add-prescription/add-prescription.module';

@NgModule({
  declarations: [
    SortByPipe,
    MedicineInvoiceComponent,
    ConsultationInvoiceComponent
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgbModule,
    NgbDatepickerModule,
    NgSelect2Module,
    FormsModule,
    ReactiveFormsModule,
    DoctorChangePasswordModule,
    PatientsChangePasswordModule,
    AddPrescriptionModule,
    DownloadEazyopModule,
  ],
  exports: [
    NgxSpinnerModule,
    NgbModule,
    NgbDatepickerModule,
    NgSelect2Module,
    FormsModule,
    ReactiveFormsModule,
    SortByPipe,
    DownloadEazyopModule,
    ConsultationInvoiceComponent,
    MedicineInvoiceComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    NgbActiveModal
  ]
})
export class SharedModule { }
