<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="main-wrapper">
  <router-outlet></router-outlet>
</div>
<!-- Add Event Modal -->
<div class="modal fade none-border" id="my_event">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Event</h4>
        <button type="button" class="close border-0" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body"></div>
      <div class="modal-footer justify-content-center">
        <button type="button" class="btn btn-success save-event submit-btn">Create event</button>
        <button type="button" class="btn btn-md bg-danger-light delete-event submit-btn" data-dismiss="modal"><i
            class="fas fa-trash"></i></button>
      </div>
    </div>
  </div>
</div>
<!-- /Add Event Modal -->
<!-- Add Category Modal -->
<div class="modal fade" id="add_new_event">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Add Category</h4>
        <button type="button" class="close border-0" data-dismiss="modal" aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label>Category Name</label>
            <input class="form-control form-white" placeholder="Enter name" type="text" name="category-name" />
          </div>
          <div class="form-group">
            <label>Choose Category Color</label>
            <select class="form-control form-white" data-placeholder="Choose a color..." name="category-color">
              <option value="success">Success</option>
              <option value="danger">Danger</option>
              <option value="info">Info</option>
              <option value="primary">Primary</option>
              <option value="warning">Warning</option>
              <option value="inverse">Inverse</option>
            </select>
          </div>
          <div class="submit-section text-center">
            <button type="button" class="btn btn-orange save-category submit-btn" data-dismiss="modal">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- /Add Category Modal -->
<!-- Edit Medical Detail -->
<div id="edit_medical_form" class="modal fade custom-modal d-none" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form action="#" enctype="multipart/form-data" autocomplete="off" method="post">
        <div class="modal-header">
          <h4 class="modal-title">Add new data</h4>
          <button type="button" class="close border-0" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="hidden" value="" name="id">
          <input type="hidden" value="insert" name="method">
          <div class="form-group">
            <label class="control-label mb-10"> BMI <span class="text-danger">*</span></label>
            <input type="text" name="bmi" class="form-control" value="23.7">
          </div>
          <div class="form-group">
            <label class="control-label mb-10">Heart rate </label>
            <input type="text" name="hr" class="form-control" value="89">
          </div>
          <div class="form-group">
            <label class="control-label mb-10">Weight</label>
            <input type="text" name="Weight" class="form-control" value="74">
          </div>
          <div class="form-group">
            <label class="control-label mb-10">Fbc</label>
            <input type="text" name="Fbc" class="form-control" value="140">
          </div>
          <div class="form-group">
            <label class="control-label mb-10">Created Date </label>
            <input type="text" name="dob" id="editdob" value="11/11/2019" readonly="" class="form-control">
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="submit" class="btn btn-outline btn-success ">Submit</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- /Edit Medical Detail -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel">Modal title</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">...</div>
      <div class="modal-footer">
        <button class="btn btn-orange" type="button" data-dismiss="modal" data-original-title="" title="">Close</button>
        <button class="btn btn-secondary" type="button" data-original-title="" title="">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLongTitle">Modal title</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only five centuries,</p>
        <p>but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the
          1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
          publishing</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenter"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s,</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="tooltipmodal" tabindex="-1" role="dialog" aria-labelledby="tooltipmodal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <h4>Popover in a modal</h4>
        <p>This <a class="btn btn-orange popover-test" href="#" role="button" title=""
            data-content="Popover body content is set in this attribute." data-container="#exampleModalPopovers"
            data-original-title="Popover title">button</a> triggers a popover on click.</p>
        <hr>
        <h4>Tooltips in a modal</h4>
        <p><a class="tooltip-test" href="#" title="" data-container="#exampleModalPopovers"
            data-original-title="Tooltip">This link</a> and <a class="tooltip-test" href="#" title=""
            data-container="#exampleModalPopovers" data-original-title="Tooltip">that link</a> have tooltips on hover.
        </p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="grid-modal" tabindex="-1" role="dialog" aria-labelledby="grid-modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Modal title</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body grid-showcase">
        <div class="container-fluid bd-example-row">
          <div class="row">
            <div class="col-md-4"><span>.col-md-4</span></div>
            <div class="col-md-4 ml-auto"><span>.col-md-4 .ml-auto</span></div>
          </div>
          <div class="row">
            <div class="col-md-3 ml-auto"><span>.col-md-3 .ml-auto</span></div>
            <div class="col-md-2 ml-auto"><span>.col-md-2 .ml-auto</span></div>
          </div>
          <div class="row">
            <div class="col-md-6 ml-auto"><span>.col-md-6 .ml-auto</span></div>
          </div>
          <div class="row">
            <div class="col-sm-9"><span>Level 1: .col-sm-9</span>
              <div class="row">
                <div class="col-8 col-sm-6"><span>Level 2: .col-8 .col-sm-6</span></div>
                <div class="col-4 col-sm-6"><span>Level 2: .col-4 .col-sm-6</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Save changes</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalfat" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalLabel2">New message</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="col-form-label" for="recipient-name">Recipient:</label>
            <input class="form-control" type="text" value="@fat" data-original-title="" title="">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="message-text">Message:</label>
            <textarea class="form-control" id="message-text"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Send message</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalmdo" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">New message</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="col-form-label" for="recipient-name">Recipient:</label>
            <input class="form-control" id="recipient-name" type="text" value="@Mat" data-original-title="" title="">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="message-text">Message:</label>
            <textarea class="form-control"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Send message</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalgetbootstrap" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">New message</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <label class="col-form-label" for="recipient-name">Recipient:</label>
            <input class="form-control" type="text" value="@getbootstrap" data-original-title="" title="">
          </div>
          <div class="form-group">
            <label class="col-form-label" for="message-text">Message:</label>
            <textarea class="form-control"></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" type="button" data-dismiss="modal" data-original-title=""
          title="">Close</button>
        <button class="btn btn-orange" type="button" data-original-title="" title="">Send message</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade bd-example-modal-xl" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Large modal</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">...</div>
    </div>
  </div>
</div>
<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myLargeModalLabel">Large modal</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">...</div>
    </div>
  </div>
</div>
<div class="modal fade bd-example-modal-sm" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="mySmallModalLabel">Small modal</h4>
        <button class="close border-0" type="button" data-dismiss="modal" aria-label="Close" data-original-title=""
          title=""><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">...</div>
    </div>
  </div>
</div>
<!-- Model -->
<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="acc_title"></h4>
        <button type="button" class="close border-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p id="acc_msg"></p>
      </div>
      <div class="modal-footer">
        <a class="btn btn-success si_accept_confirm">Yes</a>
        <button type="button" class="btn btn-md bg-danger-light si_accept_cancel" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade d-none" id="deleteNotConfirmModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="acc_title">Inactive Service?</h4>
        <button type="button" class="close border-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p id="acc_msg">Service is Booked and Inprogress..</p>
      </div>
      <div class="modal-footer">

        <button type="button" class="btn btn-md bg-danger-light si_accept_cancel" data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
<!-- /Model -->

<!-- Edit Details Modal -->
<div class="modal fade" id="edit_invoice_report" aria-hidden="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Edit Invoice Report</h4>
        <button type="button" class="close border-0" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="row form-row">
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Invoice Number</label>
                <input type="text" class="form-control" value="#INV-0001">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Patient ID</label>
                <input type="text" class="form-control" value="	#PT002">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Patient Name</label>
                <input type="text" class="form-control" value="R Amer">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Patient Image</label>
                <input type="file" class="form-control">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Total Amount</label>
                <input type="text" class="form-control" value="₹200.00">
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label>Created Date</label>
                <input type="text" class="form-control" value="29th Oct 2019">
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-orange btn-block">Save Changes</button>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- /Edit Details Modal -->