import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getDoctorNotifications() {
    return this.http.get(this.baseUrl + '/v1/doctor/notifications');
  }

  storeDoctorFireBaseToken(token) {
    let request = {
      'firebaseId': token,
      'isDeleted': false
    };

    return this.http.post(this.baseUrl + '/v1/doctor/firebaseUpdate', request);
  }

  deleteDoctorFireBaseToken(token) {
    let request = {
      'firebaseId': token,
      'isDeleted': true
    };

    return this.http.post(this.baseUrl + '/v1/doctor/firebaseUpdate', request);
  }


  storePatientFireBaseToken(token) {
    let request = {
      'firebaseId': token,
      'isDeleted': false
    };

    return this.http.post(this.baseUrl + '/v1/patient/firebaseUpdate', request);
  }

  deletePatientFireBaseToken(token) {
    let request = {
      'firebaseId': token,
      'isDeleted': true
    };

    return this.http.post(this.baseUrl + '/v1/patient/firebaseUpdate', request);
  }

}
