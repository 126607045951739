import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/services/doctor.service';

@Component({
  selector: 'app-medicine-invoice',
  templateUrl: './medicine-invoice.component.html',
  styleUrls: ['./medicine-invoice.component.scss']
})
export class MedicineInvoiceComponent implements OnInit {

  @Input() invoice;

  constructor() { }

  ngOnInit(): void {

  }

}
