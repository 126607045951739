import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MustMatch } from "./must-match.validator";

@Component({
  selector: 'app-doctor-change-password',
  templateUrl: './doctor-change-password.component.html',
  styleUrls: ['./doctor-change-password.component.scss']
})
export class DoctorChangePasswordComponent implements OnInit {
  submitted = false;
  public showPassword: boolean;
  public showPasswordConfirm: boolean;
  changePasswordForm: FormGroup;

  constructor(private authenticationService: AuthenticationService,
    private toastr: ToastrService, private router: Router,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.changePasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      });
  }

  get f() { return this.changePasswordForm.controls; }

  onReset() {
    this.submitted = false;
    this.changePasswordForm.reset();
  }


  onSubmit() {

    if (this.changePasswordForm.invalid) {
      return;
    }

    let request = {
      "newPassword": this.changePasswordForm.value.password
    }

    this.authenticationService.changeDoctorPassword(request)
      .subscribe(res => {
        this.toastr.success('', 'Password updated successfully!');
        this.activeModal.dismiss('success');
        this.router.navigate(['/patient/dashboard']);
      }, err => {
        this.toastr.error('', 'Error occured while changing password');
      });
  }

}
