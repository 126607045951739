<div class="card" *ngIf="invoice">
    <div class="row">
        <div class="col-lg-8">
            <p class="font-weight-bold store-text">{{invoice?.clinic_name}}</p>
            <div class="m-0 text-head">
                <p class="m-0">Branch :MOTHI NAGAR
                </p>
                <p class="m-0"> Address
                    :8-3-228/1297, near Birla Ice Cream, Rahmat Nagar,</p>
                Hyderabad, Telangana 500045
                <p class="m-0"> Phone Number: +91
                    9100 000 000</p>
                <p class="m-0">
                    GSTIN:32342r52954P1ZS</p>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="mt-5">
                <div class="m-0 desc">
                    <p class="m-0">Bill No. :14054DS0008128</p>
                    <p class="m-0"> Date/Time :Nov 27 2022 2:52PM</p>
                    <p class="m-0">Cont. No. :9160199180 </p>
                    <p class="m-0">Name: {{invoice.patient_name}}</p>
                    <p class="m-0">Dr.: {{invoice.doctorName}}</p>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table>
                    <tr class="text-center text-white bg-blue">
                        <th class="p-15">ITEM</th>
                        <th class="p-15">QTY</th>
                        <th class="p-15">HSN CODE</th>
                        <th class="p-15">MFRS</th>
                        <th class="p-15">BATCH NO</th>
                        <th class="p-15">EXPIRY</th>
                        <th class="p-15">SCH</th>
                        <th class="p-15">MRP</th>
                        <th class="p-15">CGST%</th>
                        <th class="p-15">SGST%</th>
                        <th class="p-15">TAXABLE</th>
                        <th class="p-15">TOTAL AMOUNT</th>
                    </tr>
                    <tr class="text-center" *ngFor="let med of invoice.bill.medicines">
                        <td class="p-15 border-blue">{{med.name}}</td>
                        <td class="p-15 border-blue">{{med.quantity}}</td>
                        <td class="p-15 border-blue">{{med.hsn}}</td>
                        <td class="p-15 border-blue">{{med.mfr}}</td>
                        <td class="p-15 border-blue">{{med.batch}}</td>
                        <td class="p-15 border-blue">{{med.expiry}}</td>
                        <td class="p-15 border-blue">H</td>
                        <td class="p-15 border-blue">{{med.mrp}}</td>
                        <td class="p-15 border-blue">{{med.cgst}}</td>
                        <td class="p-15 border-blue">{{med.sgst}}</td>
                        <td class="p-15 border-blue">155.63</td>
                        <td class="p-15 border-blue">183.90</td>
                    </tr>
                    <tr class="text-center">
                        <th class="p-15" colspan="10"></th>
                        <th class="p-15 border-blue border-right-0">Net Total</th>
                        <th class="p-15 border-blue border-left-0">{{invoice.bill.netAmount}}</th>
                    </tr>
                    <tr class="text-center">
                        <th class="p-15" colspan="10"></th>
                        <th class="p-15 border-right-0 border-blue">Discount</th>
                        <th class="p-15 border-blue border-left-0">{{invoice.bill.totalDiscount}}</th>
                    </tr>
                    <tr class="text-center">
                        <th class="p-15" colspan="10"></th>
                        <th class="p-15 border-right-0 border-blue">Total</th>
                        <th class="p-15 border-blue border-left-0">{{invoice.bill.roundOff}}</th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>