<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Clinics</h4>
    <button type="button" class="btn-close border-0 bg-white" aria-label="Close"
      (click)="activeModal.dismiss('Cross click')">
      &times;
    </button>
  </div>
  <div class="modal-body">
    <div class="clinic-content clinic-border my-2 p-2 cursor-p" *ngFor="let clinic of clinics">
      <div (click)="selectClinic(clinic)">
        <h4 class="clinic-name"><a>{{clinic.clinicName}}</a></h4>
        <div class="clinic-details mb-0">
          <h4 class="clinic-direction"> <i class="fas fa-map-marker-alt px-1"></i>
            {{ clinic.addressLine1 }}, {{ clinic.addressLine2 }}, {{ clinic.city }}, {{ clinic.state }}.
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>